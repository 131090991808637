import {
  simulateContract,
  writeContract,
  waitForTransactionReceipt,
} from '@wagmi/core';
import { wagmiConfig } from '../../core/constants/rainbow-kit-config';
import {
  MintRequest,
  TOKEN_ABI,
  TOKEN_CONTRACT_ADDRESS,
} from '@chad-mint/shared';
import { parseEther } from 'viem';

export async function whitelistMint(
  amount: bigint,  
  mintRequest: MintRequest,
  signature: string,
  price?: bigint,
) {
  if(!price) return;
  const { request } = await simulateContract(wagmiConfig, {
    abi: TOKEN_ABI,
    address: TOKEN_CONTRACT_ADDRESS,
    functionName: 'mint',
    args: [
      amount,
      {
        account: mintRequest.account as `0x${string}`,
        mintLimit: BigInt(mintRequest.mintLimit),
      },
      signature as `0x${string}`,
    ],
    value: price * BigInt(amount),
  });
  const hash = await writeContract(wagmiConfig, request);
  await waitForTransactionReceipt(wagmiConfig, { hash });
}

export async function publicMint(
  amount: bigint,
  price?: bigint
) {
  if(!price) return;
  const { request } = await simulateContract(wagmiConfig, {
    abi: TOKEN_ABI,
    address: TOKEN_CONTRACT_ADDRESS,
    functionName: 'publicMint',
    args: [
      amount
    ],
    value: price * BigInt(amount),
  });
  const hash = await writeContract(wagmiConfig, request);
  await waitForTransactionReceipt(wagmiConfig, { hash });
}