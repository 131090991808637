export enum MintStages {
  None,
  PreSale,
  Public,
}

export const MintStagesLabel: Record<number, string> = {
  [MintStages.None]: 'MINT IS NOT LIVE',
  [MintStages.PreSale]: 'Whitelist Phase',
  [MintStages.Public]: 'Public Phase',
};
