import styles from './mint-dialog.module.scss';
import { CircularProgress, Dialog } from '@mui/material';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import { useMintFacade } from '../../hooks/use-mint-facade';
import { TransactionStatus } from '@chad-mint/shared';
import RoundLogo from '../../../../assets/mode_round.png';
export interface MintDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
export function MintDialog(props: MintDialogProps) {
  const { isOpen, onClose } = props;
  const { mintStatus, mintError } = useMintFacade();

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (mintStatus === TransactionStatus.InProgress) {
          return;
        }
        onClose();
      }}
      className={styles['paper']}
    >
      {mintStatus === TransactionStatus.Error && <div>{mintError}</div>}
      {mintStatus === TransactionStatus.Success && (
        <>
          <div className={styles['logo']}>
            <img src={RoundLogo} alt={'round logo'} />
          </div>
          <div className={styles['success-content']}>
            <div>Mint Successfully Executed!</div>
          </div>
          <CheckIcon />
        </>
      )}
      {mintStatus === TransactionStatus.InProgress && (
        <>
          <CircularProgress /> Minting ...
        </>
      )}
    </Dialog>
  );
}
