import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { http, createConfig } from "wagmi";
import { CHAIN, JSON_RPC_PROVIDER, PROJECT_ID } from "./env-configs";
import { QueryClient } from "@tanstack/react-query";
import {
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  injectedWallet,
  phantomWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        metaMaskWallet,
        injectedWallet,
        rainbowWallet,
        walletConnectWallet,
        phantomWallet,
        coinbaseWallet,
      ],
    },
  ],
  {
    appName: "Today The Game",
    projectId: PROJECT_ID,
  }
);
export const wagmiConfig = createConfig({
  chains: [CHAIN],
  connectors,
  transports: {
    [CHAIN.id]: http(JSON_RPC_PROVIDER),
  },
});

export const queryClient = new QueryClient();
