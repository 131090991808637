import { useReadContract } from 'wagmi';
import { TOKEN_ABI, TOKEN_CONTRACT_ADDRESS } from '@chad-mint/shared';

export function useMaxSupply() {
  const { data: maxSupply } = useReadContract({
    abi: TOKEN_ABI,
    address: TOKEN_CONTRACT_ADDRESS,
    functionName: 'maxSupply',
  });

  return maxSupply;
}
