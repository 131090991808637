import styles from './app.module.scss';
import { Outlet } from 'react-router-dom';
import { Background } from '../background/background';
import { Header } from '../header/header';

export function App() {
  return (
    <div className={styles['container']}>
      <Background />
      <div className={styles['header']}>
        <Header />
      </div>
      <div className={styles['content']}>
        <Outlet />
      </div>
    </div>
  );
}
