import styles from './logo.module.scss';
import { useRouter } from '../../../routing/hooks/use-router';
import ModeChadLogo from '../../../../assets/chad_logo_transparent.png';

export function Logo() {
  const { goToExternalLink } = useRouter();
  return (
    <div className={styles['container']}>
      {/* <ModeChadLogo
        className={styles['logo']}
        onClick={() => goToExternalLink('https://www.mode.network/')}
      /> */}
      <img src={ModeChadLogo} className={styles['logo']} />
      {/* <div className={styles['text']}>CHADS</div> */}
    </div>
  );
}
