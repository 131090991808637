import React, { StrictMode } from 'react';
import { Web3Root } from '../web3-root/web3-root';
import { RouterProvider } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { browserRouter } from '../../../routing/constants/browser-router';

export function Root() {
  return (
    <StrictMode>
      <Web3Root darkMode>
        <StyledEngineProvider injectFirst>
          <RouterProvider router={browserRouter} />
        </StyledEngineProvider>
      </Web3Root>
    </StrictMode>
  );
}
