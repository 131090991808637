import React from 'react';
import { queryClient, wagmiConfig } from '../../constants/rainbow-kit-config';
import { WagmiProvider } from 'wagmi';
import { QueryClientProvider } from '@tanstack/react-query';
import {
  darkTheme,
  lightTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

export interface Web3RootProps {
  children: React.ReactNode;
  darkMode?: boolean;
}

export function Web3Root(props: Web3RootProps) {
  const { children, darkMode } = props;
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkMode ? darkTheme() : lightTheme()}>
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
