import styles from './mint-page.module.scss';
import { ReactComponent as ModeChadLogo } from '../../../../assets/icons/mode_logo.svg';
import ChadImage from '../../../../assets/chad_a.gif';
import { WalletConnectButton } from '../../../access-control/components/wallet-connect-button/wallet-connect-button';
import classNames from 'classnames';
import { WhitelistChecker } from '../../../whitelist/components/whitelist-checker/whitelist-checker';
import { useRouter } from '../../../routing/hooks/use-router';
import { useMintFacade } from '../../hooks/use-mint-facade';
import { MintDialog } from '../mint-dialog/mint-dialog';
import { Button } from '../../../ui/components/button/button';
import { useTotalSupply } from '../../hooks/use-total-supply';
import { useMaxSupply } from '../../hooks/use-max-supply';
import { formatEther } from 'viem';
import { useAccount } from 'wagmi';
import { MintStagesLabel } from '@chad-mint/shared';

export function MintPage() {
  const { isConnected } = useAccount();
  const { goToExternalLink } = useRouter();
  const {
    currentStage,
    canMintPublic,
    canMintWhitelist,
    executeTokenMint,
    price,
    isOpenMint,
    closeMintModal,
  } = useMintFacade();
  const totalSupply = useTotalSupply();
  const maxSupply = useMaxSupply();
  const totalSupplyRender =
    totalSupply !== undefined ? totalSupply.toString() : '--';
  const maxSupplyRender = maxSupply !== undefined ? maxSupply.toString() : '--';
  const priceRender = price !== undefined ? formatEther(price) : '--';

  function getMainButton(walletConnectButtonClassname?: string) {
    if (!isConnected) {
      return (
        <WalletConnectButton
          className={classNames(
            styles['wallet-connect-button'],
            walletConnectButtonClassname
          )}
        />
      );
    }
    if (canMintWhitelist) {
      return (
        <Button
          onClick={executeTokenMint}
          className={styles['wallet-connect-button']}
          fullWidth
        >
          Whitelist Mint
        </Button>
      );
    }

    if (canMintPublic) {
      return (
        <Button
          onClick={executeTokenMint}
          className={styles['wallet-connect-button']}
          fullWidth
        >
          Public Mint
        </Button>
      );
    }

    return (
      <WalletConnectButton
        className={classNames(
          styles['wallet-connect-button'],
          walletConnectButtonClassname
        )}
      />
    );
  }

  return (
    <div className={styles['container']}>
      <div className={styles['chad-container']}>
        <img src={ChadImage} alt="chad 1" />
      </div>
      <div className={styles['data']}>
        <div className={styles['information']}>
          <div className={styles['top']}>
            <span>125</span> Chads
          </div>
          <div className={styles['middle']}>
            <div className={styles['text']}>living on</div>
            <ModeChadLogo
              className={styles['icon']}
              onClick={() => goToExternalLink('https://www.mode.network/')}
            />
          </div>
        </div>
        <div className={styles['stage']}>
          SOLD OUT! <br /> Available to purchase on{' '}
          <a
            href="https://element.market/collections/the-chads"
            target="_blank"
          >
            Element
          </a>
        </div>

        <div className={styles['mint-data']}>
          <div
            className={classNames(styles['value-container'], styles['price'])}
          >
            <div className={styles['value']}>
              {priceRender} <span className={styles['text']}>Eth</span>
            </div>
            <div className={styles['label']}>Price</div>
          </div>
          <div
            className={classNames(styles['value-container'], styles['supply'])}
          >
            <div className={styles['value']}>
              <span>{totalSupplyRender}/</span>
              {maxSupplyRender}
            </div>
            <div className={styles['label']}>Supply</div>
          </div>
          {/* <div className={styles['action-container']}>{getMainButton()}</div> */}
        </div>
        {/* <div className={styles['checker']}>
          {(currentStage === 0 || currentStage === 1) && <WhitelistChecker />}
        </div>
        {isConnected &&
          currentStage !== 0 &&
          (canMintWhitelist || canMintPublic) && (
            <WalletConnectButton className={styles['wallet-connect-button']} />
          )} */}
      </div>
      <MintDialog isOpen={isOpenMint} onClose={closeMintModal} />
    </div>
  );
}
