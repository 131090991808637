import styles from './socials.module.scss';
import { ReactComponent as XIcon } from '../../../../assets/icons/x.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/icons/telegram.svg';
import { useRouter } from '../../../routing/hooks/use-router';
export function Socials() {
  const { goToExternalLink } = useRouter();
  return (
    <div className={styles['container']}>
      <XIcon
        className={styles['icon']}
        onClick={() => goToExternalLink('https://twitter.com/Chadsfun_')}
      />
      <TelegramIcon
        className={styles['icon']}
        onClick={() => goToExternalLink('https://t.me/thechadsfun')}
      />
    </div>
  );
}
