import { useAccount } from 'wagmi';
import { WHITELIST_PAYLOADS } from '@chad-mint/shared';

export function useWhitelistFacade() {
  const { address } = useAccount();

  return {
    whitelisted: !!address && !!WHITELIST_PAYLOADS[address.toLowerCase()],
  };
}
