import styles from './header.module.scss';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/play.svg';
import { ReactComponent as PuaseIcon } from '../../../../assets/icons/pause.svg';
import { Logo } from '../logo/logo';
import { Socials } from '../socials/socials';
import { useBackgroundMusic } from '../../hooks/use-background-music';
export function Header() {
  const { play, pause, isPlaying } = useBackgroundMusic();
  return (
    <div className={styles['container']}>
      <div className={styles['top']}>
        {isPlaying && <PuaseIcon className={styles['icon']} onClick={pause} />}
        {!isPlaying && <PlayIcon className={styles['icon']} onClick={play} />}
        <div className={styles['socials']}>
          <Socials />
        </div>
      </div>
      <div className={styles['logo']}>
        <Logo />
      </div>
    </div>
  );
}
