import { MintPage } from '../../mint/components/mint-page/mint-page';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { App } from '../../core/components/app/app';

export const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <MintPage />,
      },
    ],
  },
  { path: '*', element: <Navigate to={'/'} /> },
]);
