import { useReadContract } from 'wagmi';
import { TOKEN_ABI, TOKEN_CONTRACT_ADDRESS } from '@chad-mint/shared';

export function usePrice() {
  const { data: price } = useReadContract({
    abi: TOKEN_ABI,
    address: TOKEN_CONTRACT_ADDRESS,
    functionName: 'price',
  });

  return price;
}
