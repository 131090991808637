import { BaseError } from 'viem';
import { AxiosError } from 'axios';

export function extractError(e: unknown): string {
  if (e instanceof BaseError) {
    return e.shortMessage;
  }

  if (e instanceof AxiosError) {
    if (e.response && e.response.data.message) {
      return e.response.data.message;
    }
  }

  if (e instanceof Error) {
    return e.message;
  }

  return 'Unknown reason';
}
