import { WhitelistPayload } from '../types/whitelist-payload';

export const WHITELIST_PAYLOADS: Record<string, WhitelistPayload> = {
  '0x1ea1c38a899e5b0b2d193a42e24e554914de360b': {
    request: {
      account: '0x1ea1C38A899e5B0B2d193a42e24e554914DE360B',
      mintLimit: 1,
    },
    signature:
      '0x77a559b1b62a0f827fd42fcf43f595afe249eeb1ef9f9abb4e1870b44a2623bf6bd7629f59f8097a28d2430edb4c5458dc044360f04d489a730e337a1a89a16c1c',
  },
  '0xa58cc23a546b6ce08ee258cfb54d92d4cc151ba4': {
    request: {
      account: '0xa58Cc23a546b6cE08EE258cfb54D92d4cC151Ba4',
      mintLimit: 1,
    },
    signature:
      '0xc54b289bfc47cb4fcd22207c239d40499b04a2f6a246db200da2e6422c5b06377a4db8eab69ad8b035db4e751df3397b703173f37f956f29db73ffdccebfee6b1b',
  },
  '0x90e925a44a2c8c560ba4c1bd6dea75d5e2f4150c': {
    request: {
      account: '0x90e925a44A2C8C560BA4c1BD6dEA75d5e2f4150c',
      mintLimit: 1,
    },
    signature:
      '0x5dc3aedea1a27b9c1c1e23fb5b3b8ae8cfea289b2096bb6b0d5aa5bc1f67b303291505147978d65795feef0d2400cdfd23a60679d55546dcceae0573c49c02d51c',
  },
  '0x9663d040686500bdd00432c8ecf3350551db70f7': {
    request: {
      account: '0x9663d040686500BDD00432c8EcF3350551dB70f7',
      mintLimit: 1,
    },
    signature:
      '0xb55902a508250c5ca941f2aae4b46175343815327948646e1f4d2c857f1874d86703e261f50ff2c0b1b6c8820cf9899215d1e8d1d383c0df09d761d8a7df5f181c',
  },
  '0x78b8e2aa7f2ebeaa3a38d0aa46e8fc408ddfba72': {
    request: {
      account: '0x78B8E2aa7F2EbeaA3A38D0AA46E8Fc408DDFba72',
      mintLimit: 1,
    },
    signature:
      '0x88dfe24656ed9dd7ea8a54aa2e03b935b97198b5ff3155336305c04a76dc41423ee81b0ef68ac40a7badfcbffbe12e4cc45a194b16d94fb3582c1cc73abc4ce21c',
  },
  '0x71c99b90c21923fc622213d4108751c1ac147bc9': {
    request: {
      account: '0x71c99B90c21923Fc622213D4108751C1Ac147BC9',
      mintLimit: 1,
    },
    signature:
      '0x6501c5db5c2790684591f3e9e4e80745d31ec4156eba5d6a2f34a63e5dffd5a05745a625900b8c55146e52ac81fcfa5f187be909e2263f7552fb62fc53ca67041b',
  },
  '0xcb4da70932798443faab11a29fc8695d9abbee53': {
    request: {
      account: '0xCb4DA70932798443faAB11A29fc8695d9ABBEE53',
      mintLimit: 1,
    },
    signature:
      '0x451361d37bc62f849624e9b47b9b4c7629626622e18de8bde275bae46884db6e112e40f859ec23ee03a3ebba9a9e66c587a3091bf707034862ed5d740ad434281c',
  },
  '0xe78a6d993c5cfb68a52de7e0c54cfdb967bf650f': {
    request: {
      account: '0xE78a6d993C5cfB68A52de7E0C54Cfdb967BF650F',
      mintLimit: 1,
    },
    signature:
      '0x32d0736c18e0bb1bc7ff23726721907d82a2ac9bb37c6a82897e431bb9617b7c45362ad9f058714b07272d89b381186580ea7b432af81b515b7d0c36d9e42d491c',
  },
  '0xef0ffc485084dee44f36680c5d76d11dd34a26d7': {
    request: {
      account: '0xEf0Ffc485084DEE44f36680c5d76d11DD34A26d7',
      mintLimit: 1,
    },
    signature:
      '0x04f4bc7897d95125c9c549b54e5bd651007359c496fabf24382eaa0620a46ba711566de2b958703d3022780106bcf95df55ca2c45af5782fd96bd7dcb2e380e51c',
  },
  '0xcf1b4c0ca1d7e72f4e962a3fe5d3b49b975fb5b2': {
    request: {
      account: '0xcF1b4C0cA1D7e72f4E962A3FE5d3B49B975fB5B2',
      mintLimit: 1,
    },
    signature:
      '0x98f89a35639227f9234abd82bc4d7b0a8b68c064b6be0c4c7794528fd4f51aea62b9de7b03a210d639ef91eed162df09278fc5d9e27e1b18894bf6aa19edb1631c',
  },
  '0x5eec5f3ad3d8751735915834a8104ec42545412e': {
    request: {
      account: '0x5Eec5f3ad3D8751735915834a8104ec42545412e',
      mintLimit: 1,
    },
    signature:
      '0x60287b2fa9d013498250e2c4724e90bf8189c543b6d56290bd62e895a752fb0301c58f2c9084b83145474b471ab22176eb05cfa03f5a3d29ba57bde7837415501c',
  },
  '0xae65958f84fd413802c2d86d746c3cd13ddf02dd': {
    request: {
      account: '0xaE65958f84fD413802C2D86d746C3Cd13Ddf02dd',
      mintLimit: 1,
    },
    signature:
      '0x234f2383205d379eb717f37edfb17d8aa6f4a87e8e2c715ec984b28702285140754cd29809b18c431911a267b0dde46237b23174a11cf42c63fae88b7b26c9031c',
  },
  '0x7753d5553fb06d75d16bdb802c10c9e6d59fdaf4': {
    request: {
      account: '0x7753D5553Fb06d75d16bdb802c10c9e6d59fDAf4',
      mintLimit: 1,
    },
    signature:
      '0x0934ae6f33c52a5dd4643fb8db241e69de8acca2ab95e02033f7adbdc5e6d8085d852f763b4d80fd49b11ebf76e71e9d6fe347f9ebb8572c9f47ad500a9553c81c',
  },
  '0x90d51493301e3adc90a6b2426d17be6a5d2d44cc': {
    request: {
      account: '0x90D51493301E3adc90a6B2426D17bE6A5D2d44cC',
      mintLimit: 1,
    },
    signature:
      '0x8cd8f3bd2b5bfe0fd3595b1f9bf20303cd18d68dcac16bb164ebbc6b8e06354c182088c218bb9cdaed61a4451c4b7cb5d467477b89ac60c602a871f4b0903c071c',
  },
  '0x3da10a411c45e20f6b1b37843f7b310d7086b5d1': {
    request: {
      account: '0x3DA10A411C45e20f6b1B37843F7b310D7086b5d1',
      mintLimit: 1,
    },
    signature:
      '0x74a090dd4691d692f7bd6477e001946cf464735c66058a9007a6754b5731b49a231044c1b7506976e94db3fef85bfaf38090859f4515a5dd842690291d4d5eb71b',
  },
  '0x9fa4335dd800cdb3ab8ad1e54c270e42462286af': {
    request: {
      account: '0x9FA4335dd800cDb3aB8Ad1e54c270E42462286AF',
      mintLimit: 1,
    },
    signature:
      '0x8dd5b41d220b8240044ddaf7cb1c409ab965997e4479a6d71700150801e26bf35eafbdcd3f6b0c187d45157a5e5b270cb0a8556b22e621909b9ae3e7d4a9c1bb1b',
  },
  '0x76f9a1221da8d97ac868dff5fcf7e502e10727b0': {
    request: {
      account: '0x76f9A1221da8D97ac868DfF5Fcf7e502E10727B0',
      mintLimit: 1,
    },
    signature:
      '0x86f2d78f79ec73fc4fe72d7d22f717dd6bbbb3513223f9008e1aa3e8bac166fc6b3f535571154b47eb75a4cc49ab13949d1857030442917b5731d6eff50c691d1b',
  },
  '0x2a371013a5b56e2fdf30942b4837abddb9275ca8': {
    request: {
      account: '0x2A371013a5b56E2FDf30942b4837AbDdb9275cA8',
      mintLimit: 1,
    },
    signature:
      '0x65996b5fccd167ae89602988bfd97979910b146c1ee6ced9fd42a5a4d512846c1f17152b68f9b8f9dd0aaedbc6d3c3c35f3d8598f2c6c60f7d6d1bcb843202eb1c',
  },
  '0x2a93bc3ff41e17d77b72860897821765ad6ebd5a': {
    request: {
      account: '0x2A93BC3FF41E17d77b72860897821765ad6EBD5a',
      mintLimit: 1,
    },
    signature:
      '0x3deb803d4d469d1d24332863dd58d8b4e127082e1d0b7215c3d41d0e41c42a902f9ee75f31d63eec97acefd56067b3aeb6d3634f10b5501bb92b39b9484d99d71b',
  },
  '0xedf4a5e74fcc39b8349ec3de27444cc934191320': {
    request: {
      account: '0xEdf4a5E74FcC39b8349eC3DE27444CC934191320',
      mintLimit: 1,
    },
    signature:
      '0x85e31bf481f53fad5ab26287847a3b220c8d649c4ed24262688dbb16a934e91d38f51d18636d950aaa91ffad3e54494f15764e54d6e472569e95be488101a42a1b',
  },
  '0xc2ce22856478f9b004c36902c8f0edba21b63fbe': {
    request: {
      account: '0xC2Ce22856478f9b004C36902c8f0EDBA21B63fBe',
      mintLimit: 1,
    },
    signature:
      '0x53e65ebd8f2e5ce49f27b594643ca9214dbae6f7c96e9fedcb594e55a4c46be57815591b0be0383c13908d889535dee8ad5a3bb09628166467a5364cef4fb37d1b',
  },
  '0x2697f24a0128f4bc44c37576152518ec6cd70924': {
    request: {
      account: '0x2697f24A0128F4BC44c37576152518EC6Cd70924',
      mintLimit: 1,
    },
    signature:
      '0x8f6a096a3f6f497941beab6640854fde9b8f78f0028ca745490bc30c4e8e6a0024b4bb21e36371d05b2064005cd18cfad1cb8bcff44215dd1d64905520ca4b8a1b',
  },
  '0x626fe43adfc5aa4ec9d49ae4f61a108d8d390d5e': {
    request: {
      account: '0x626fE43ADfC5AA4eC9d49AE4F61A108D8D390d5e',
      mintLimit: 1,
    },
    signature:
      '0xe7de34ebc680c75591ac072eebcde735fa92439fffc16ca0d96f4b0483c613a50864634783542b4dea650ff4b7dd2ffef865c38c30aaf117ba7b8759dc86cacb1c',
  },
  '0xbc908d55d31d65e497e9b7b36821df0362f2d6d4': {
    request: {
      account: '0xbc908d55D31d65E497E9B7b36821DF0362F2D6D4',
      mintLimit: 1,
    },
    signature:
      '0xb58806f0b84e24cd0807e98932f3b971107e8d58776063cf70d8c2f9e0954abf6da92fef9718d61765d5a10cd18b4528ab45c848e4a032b1f370e010b2a62fef1b',
  },
  '0x6bc4126fcb6f77a1e48ee083e2c7f4a701d49572': {
    request: {
      account: '0x6Bc4126fCb6F77A1e48Ee083e2C7F4A701d49572',
      mintLimit: 1,
    },
    signature:
      '0xd48c6637757fd54287b3438b7e85a2da295904a2ede6262b483026a1c80a69b92d98fa183b0b76cb4d0367304a29942c98bb5e907d9053785c3634ed9562fff61c',
  },
  '0x65056f8c991847dd55dd2cc7f659271784a9745a': {
    request: {
      account: '0x65056F8C991847DD55DD2cC7F659271784a9745a',
      mintLimit: 1,
    },
    signature:
      '0x41636199390ac573dc00b4b5f5aec487b727fc1477744c190c1cf092d8b811b750c26a640532f949b7fb465617cf19e93610ac9914235f4f90568478e863b7871c',
  },
  '0x3b3460489b82d8ff150ecef6379a3d915ffb39a6': {
    request: {
      account: '0x3b3460489b82D8FF150eCEF6379a3d915Ffb39a6',
      mintLimit: 1,
    },
    signature:
      '0x6c4d06f8e11a68664f53f6bae87485a77f34f32b0af1b407d1f4e24d69295e311bbd83322e17db8c3b2fe2dfa806bf94f48f54f0d36c0262265b21cb4c58f5ed1b',
  },
  '0xe87c8589a31c9da7d0800e84b6f43ab99e4e1670': {
    request: {
      account: '0xe87C8589A31C9dA7D0800e84b6F43AB99E4E1670',
      mintLimit: 1,
    },
    signature:
      '0x87e308e6d00456a667f3d0e50d4598d3174c632da380d9e2f9c5fb217aa345e851ceaaa55a48fe5507d78bee37342b8d6ce037808e09624b5923f08f694ef86c1c',
  },
  '0xe7c6fe3292e6a589baefb15dbd35264a2f5fa247': {
    request: {
      account: '0xE7C6Fe3292e6a589bAEfb15DBd35264a2f5FA247',
      mintLimit: 1,
    },
    signature:
      '0x41f55a7b71958e7bd4050698c8740bdbae5645d6f9c6d9f2c581ca8bc9d1b41f15b92649caa2eb7213183d207a9aa5762e4d2eff0a41cca51be4889c528bb7461b',
  },
  '0x630848d99bf5552c70dc6ae9f766bd698f5c44a4': {
    request: {
      account: '0x630848d99bF5552c70DC6AE9F766Bd698F5c44a4',
      mintLimit: 1,
    },
    signature:
      '0xe9e96e09062b0d90d587e3401c34002648a6fe907abff3098803005ffabf3b366d5fd5d042f7371dd99abe696ae7d28a653022cd6df8a29a1eafc7e9e2d42e691c',
  },
  '0x5e51b5e056c7f678355b35670b776a6747c16358': {
    request: {
      account: '0x5e51b5e056C7F678355b35670B776A6747c16358',
      mintLimit: 1,
    },
    signature:
      '0xa8ec8cd30b69a45bcbfd33b5aed33c4807faa3255440f9ef5549f4ee1bf51323520ceefb1b6654b55da05dabbf4b66b1ee6ecc18265b760f18bbb294b65629b51c',
  },
  '0xc05ac07b7fedb964c993220b6675645c88eacf5f': {
    request: {
      account: '0xC05aC07b7FEDB964c993220B6675645c88EAcF5F',
      mintLimit: 1,
    },
    signature:
      '0x1715cea2a1656be29c8f65065a8e9ebfd6c6531d9082f4bf72d9db59741214b7679b4d42b26282277de978ee02eb3155afe631f00f1da2aa9512bf11aabe26331b',
  },
  '0xac8ebeb5f3a0187d49e85d304547609387512eb2': {
    request: {
      account: '0xaC8EbEb5F3a0187d49e85d304547609387512EB2',
      mintLimit: 1,
    },
    signature:
      '0x9a3be1f0224e62594628aba928504ed3d56d11a40bcf1676cd53238ec2d437d92ae3cffa2618e0e04c6f8b95b099bb5f6f625889a0ceb783e14e8733c38bd85c1c',
  },
  '0xf30f0f7cbc2088295284348cae4b849aa24f2b20': {
    request: {
      account: '0xF30F0f7cbC2088295284348cAE4B849AA24f2b20',
      mintLimit: 1,
    },
    signature:
      '0x2867e0845b32f4410bae49ea4790bacca236e945212d1ea217fdd1d3a09ec8d20eada2942edbd6240e1a2db5ef8f3a51097ed294168cc0e3fa5bd659e02bcedc1b',
  },
  '0xa5f146cbd3ee13f482315dd0f873c2bfbbc5f2c4': {
    request: {
      account: '0xa5f146cBd3eE13F482315dD0F873c2bFbBc5F2C4',
      mintLimit: 1,
    },
    signature:
      '0x288936ed01e83c026e51043eaac7f0f51d076161ed35e4ada8b95bd29d6d16e654571eed5d944ae81aa5b1a3aab02d4d4b7c1b6e0b7dc9c77049163f844b77e31c',
  },
  '0xe6ee9a0f0789aa137838d7b218230f33eea768f6': {
    request: {
      account: '0xe6eE9a0f0789aa137838d7B218230f33eea768F6',
      mintLimit: 1,
    },
    signature:
      '0x8aa90be9d2867dd6188a2202f60db908d29dc7763f87d9df1a445a2dd81fe0927e64f096795894f9591bed3091c73749baef28c94c7e542e2aaa60d2c106cba11b',
  },
  '0xd35bf70a5e8f7c358a82bf7887ceb239e81d775e': {
    request: {
      account: '0xD35bf70A5E8f7C358A82Bf7887Ceb239E81D775e',
      mintLimit: 1,
    },
    signature:
      '0x0d04dfa8d5eb6c9b5b21a8b7f75e8c78fd854cf08b027352208b219f21b048577387af2cab09e44b6b7e4c43f4ab51a29feeeec3e424c4a3b4fdcdadce2d04e81b',
  },
  '0x737dd19a1a476cd2a8ed70272d8f7221d681803b': {
    request: {
      account: '0x737dD19A1a476cD2A8ed70272D8f7221d681803b',
      mintLimit: 1,
    },
    signature:
      '0x995181586eda7e9f84c5b33774807ad61b27afadfe618905925683d7d663964e662ba23dc86dc809dce8d7f34d4b4902e2b92422797c90dcf7f8f089a695ee591c',
  },
  '0x504d0845fee88c2acfa71cc7b794f26d3cf1c489': {
    request: {
      account: '0x504D0845FEE88c2aCFA71CC7B794f26D3CF1C489',
      mintLimit: 1,
    },
    signature:
      '0xd4f3daf30e7a1fd7ef1b16bdb0c8ee11642ab4d091527e1f419a5d293bd2913563478132252956811eb53b9d283b802602a040d4976d25df1d3806dec6578e9b1b',
  },
  '0xf9e0be1042a8854a4bfebbf3099039e8e7744763': {
    request: {
      account: '0xF9E0Be1042A8854a4BFEBbf3099039e8E7744763',
      mintLimit: 1,
    },
    signature:
      '0x87806fc2e0dbe15ffd436682dbfd59c2389f91bf76a89ae0dafc6676903a38aa2262da5fda669c58bc40c6b24b1ff270fdbbb575bfbd88ce70a88e49945a5c6c1b',
  },
  '0x673b8f5ed1520ec242d2e633defe45efa360bdc5': {
    request: {
      account: '0x673B8F5ed1520Ec242D2e633Defe45eFA360bdC5',
      mintLimit: 1,
    },
    signature:
      '0x35c8e9343b003319e26eac3b44c0fc320babae166e0a1822f95dcb7599f65b6310e1b31408941ed4447d0412ae97bfa098d4f440979876d10db32bf2bdd750d71c',
  },
  '0x0cac11a7d29ab8c87d8fedb9cc736146f4fe8e84': {
    request: {
      account: '0x0Cac11a7D29aB8C87D8fedB9Cc736146f4fe8E84',
      mintLimit: 1,
    },
    signature:
      '0xa9c1f1ecea9ff378f5f0e7e682f2744a5fe9d9cd5a4ccb05ef89ee3d49bd4e2b05892bda08ed45daaf33c5b4d2ee9fa82b3480864bc4d6943e87d91ed54ab5da1b',
  },
  '0x466ee20e7a6547de7d06089d3566108aa9b19898': {
    request: {
      account: '0x466ee20e7A6547DE7D06089d3566108AA9b19898',
      mintLimit: 1,
    },
    signature:
      '0x73c09e329feff0303b1f56bf358d72eda788ec05c2f509e67cb287270baac5130436b55bde855033ef8f98e44a573a6ce2e58d2daf25ed016897e24dd67b151f1b',
  },
  '0x186c5c2fe198f53092867e70c529d1895289fb48': {
    request: {
      account: '0x186C5c2FE198F53092867e70c529D1895289Fb48',
      mintLimit: 1,
    },
    signature:
      '0xefe7b4dd8c8985f31933f59c8f16bba9599ca600b39724ce8fe04b9d0d00c3df2228b087f45c2876fa557076f0a4948a4132173ef931dab500ecc4a6042168151b',
  },
  '0xb4fb31e7b1471a8e52dd1e962a281a732ead59c1': {
    request: {
      account: '0xB4fb31E7B1471A8e52dD1e962A281a732EaD59c1',
      mintLimit: 1,
    },
    signature:
      '0xe98436d34ca77317189aa0bc4ec661b4b93f4a66e0227883af8ca3597bf47db14cdbdef66a76d4e76ef5b0ddd30034b2b057e7220a70011f50d8de3531d52e491c',
  },
  '0xa8a0529fdece23c83f603c15984faa6b69958b40': {
    request: {
      account: '0xa8a0529FDeCE23C83f603C15984faa6b69958b40',
      mintLimit: 1,
    },
    signature:
      '0x47597c61e6ab961717aaf9799cd2cdcc0915141f3e87577a47d34352378582ea03c3f0b425c191e630c580f37ac55b0f9c6ec203f14a41cbf9188b7e53a4ba121c',
  },
  '0x3f3429d28438cc14133966820b8a9ea61cf1d4f0': {
    request: {
      account: '0x3f3429D28438Cc14133966820b8A9Ea61Cf1D4F0',
      mintLimit: 1,
    },
    signature:
      '0xe5d11d45f337d89930c32d4f71415b2df7603d7a8ecfd97d06eb80594457c64b052d814f3748c742cfa5e04f3defda4bd2a109ee8967a6b0597b765d608d05f01b',
  },
  '0x5ec6abff9bb4c673f63d077a962a29945f744857': {
    request: {
      account: '0x5EC6abfF9BB4c673f63D077a962A29945f744857',
      mintLimit: 1,
    },
    signature:
      '0x0f4ae3c8b37c68d5081fed22daeac6ca3ac6ca3681960244bcd490a7b42dbc473796492e94d5245e35151d349b248e367c42c6579b7cffc35b4a7030979b4cac1c',
  },
  '0xd3b02d999c681bd8b75f340fa7e078ce9097bf23': {
    request: {
      account: '0xd3B02d999C681BD8B75F340FA7e078cE9097bF23',
      mintLimit: 1,
    },
    signature:
      '0xc5485ffbb6cd521958183fb1e2bd62c3ab32efe5a77d2a59bcf69b5f404bbbdd72acefb710fb678b137422f8d177531f90b70410f99d5c8957272e44057659111b',
  },
  '0x1e78322001bdc85f21a7a2f3429dc55c1c13cfae': {
    request: {
      account: '0x1e78322001BdC85F21a7a2f3429Dc55C1c13cFAE',
      mintLimit: 1,
    },
    signature:
      '0x5be496e341a42c068a2ca68d96ebdd6be09b813efb30a2d873441de953dfb2b31c5ae00df654960bb4194ac8b8fd2b9126f75b39f0d66a8210bcc06f59b2a9001b',
  },
  '0xfa4fc4ec2f81a4897743c5b4f45907c02ce06199': {
    request: {
      account: '0xFa4FC4ec2F81A4897743C5b4f45907c02ce06199',
      mintLimit: 1,
    },
    signature:
      '0x93ad414ca31e096dc260816eab3b29a873ecdff56419237ae46f98422535d3b766d08290e7f2bea95f954b8d632a7da450c3cd364d4d6c83132e2d07aa8750701b',
  },
  '0xbf891e7efcc98a8239385d3172ba10ad593c7886': {
    request: {
      account: '0xBf891E7eFCC98A8239385D3172bA10AD593c7886',
      mintLimit: 1,
    },
    signature:
      '0x5bfd7485069864cf5760a301f4f08144889e251ad4162b7bf140bb5c63c712b02081e6c9b98e080350a089f80b891e83945e0a8518463136007e7bddde6781731b',
  },
  '0xb3b45cbc7b4650035a15c4e858bf7c21667a4741': {
    request: {
      account: '0xb3B45CbC7B4650035a15C4E858bF7C21667A4741',
      mintLimit: 1,
    },
    signature:
      '0x6b5ebc3e6434dfb61519f40e166f152d7640e4712412452debea9e6a1bdc669941e8b179b4172cf1a29602d3138ab83ba50639a1461a2032bf694f9d2487e6121b',
  },
  '0xe67e43b831a541c5fa40de52ab0afbe311514e64': {
    request: {
      account: '0xe67e43b831A541c5Fa40DE52aB0aFbE311514E64',
      mintLimit: 1,
    },
    signature:
      '0x4e5f0527643c545521a2f85d510fb47ca3afa685c3c7328072b8a50c2c548a1e557d02d09ad9b1a7575ff40fca4cd879329db97b32805432da853b5256c49c0c1b',
  },
  '0x0399e7720626dd7a7b0577771486f25f573cac09': {
    request: {
      account: '0x0399E7720626Dd7A7B0577771486F25f573CAC09',
      mintLimit: 1,
    },
    signature:
      '0xeb50a97fb1c714121b87d6badf441ce19befadf2e7c9e522ebf17087585c70fb45abb303f376a6492dc9222516859905846f0b24c71d1f749a67a39325a7b61f1b',
  },
  '0x1003977a308a40c076637f5b7a45db5c75db3782': {
    request: {
      account: '0x1003977a308A40c076637F5B7a45Db5c75db3782',
      mintLimit: 1,
    },
    signature:
      '0x882ee04045136d74da25d74909772500fe490fe58ece64a1a31d8ffb3830a9305d1c6089ea2459bea7d9e5d6aec49843bd60814279641e270a1062499de40ac91c',
  },
  '0x48c77053d8b81711c93fd946cdac75ab56f68d64': {
    request: {
      account: '0x48C77053D8b81711c93FD946cDAc75aB56f68D64',
      mintLimit: 1,
    },
    signature:
      '0xe3d410c071b683ebbefcdadd5b5bb257ab64d789ef0ffdd6d07acfca827060351ed6b9cafc54dc343c775933617343ef9cc53a957f836ce2c69fdd92937fad111b',
  },
  '0xd53713dc80a11fa10e21fe7a38eca741e1f2ac83': {
    request: {
      account: '0xd53713dC80a11FA10e21fE7a38eCa741E1f2AC83',
      mintLimit: 1,
    },
    signature:
      '0x8d5c5fc5b14b7df01dd7ee848f010390371d5bb16134b2fd3fe14f03ed227d3f3bc6489248af8b4ec7989fdcf4fb76f040972629f4fdfa45eb9b265d62a99c3b1b',
  },
  '0x24566b057a507446b7f9d735f4f7435eee10a55b': {
    request: {
      account: '0x24566b057a507446B7F9d735F4F7435eEE10A55b',
      mintLimit: 1,
    },
    signature:
      '0xe6167f4344a0ee1c1b8230db8fa5d5439367c37c11da65c8a640ea581b37028d1ec8050b5e7adab95ea727f4630c2825abc39fa393b35ca078c029624249be171c',
  },
  '0x045d8fc2db6116c70ff5cb7898d0e427a8f2801b': {
    request: {
      account: '0x045D8Fc2Db6116c70fF5Cb7898d0e427a8f2801B',
      mintLimit: 1,
    },
    signature:
      '0xb0512f5997fbc810d2cc90a06015a786d286e3df2d1a494d65e240acf06f42ff7a032617be2149c8a84df61669f82b5699d1847d287c180ffc932bfc2b9d4d911b',
  },
  '0xed5c73f229d1f085c441cac3d9d8dee9c44dd475': {
    request: {
      account: '0xeD5C73F229D1f085c441CAc3D9d8dee9C44dd475',
      mintLimit: 1,
    },
    signature:
      '0x1bcbfef1884c5ae086083c0e3887d7798f605f125a85b22add7904bd176e587121ad2eb854cd1ddfee6d746e97befc7d2b108aa878540a98c304f69c173fbdf51c',
  },
  '0xc7bfd896cc6a8bf1d09486dd08f590691b20c2ff': {
    request: {
      account: '0xc7Bfd896cc6A8BF1D09486Dd08f590691b20C2Ff',
      mintLimit: 1,
    },
    signature:
      '0x58b2c60dcd468bbafe52ffa9cc3edb133ec647faafa540f6c09cea275e0cd0973e4a2390e24e9740235bfeb84e4908b25d77a332e7656eb9bdd2f434e1bbeca01b',
  },
  '0xcf7be1e98dbec52f5c61b5a95e33171abda91d43': {
    request: {
      account: '0xcF7Be1E98DbEC52F5C61b5a95e33171ABDA91D43',
      mintLimit: 1,
    },
    signature:
      '0xddd7c556ad09abb75379239e7f0fa74b3f315b6c77cabdace0bde99242c090a0174887142a57592e8a55ddfcc0b0b6b37be5ba7349ca846f88f6983af32762281c',
  },
  '0x6205473922f80379fb699f97e93a8cece7959b77': {
    request: {
      account: '0x6205473922F80379fB699f97e93A8cece7959b77',
      mintLimit: 1,
    },
    signature:
      '0x5a8708569c881fa1b32ae542ed42a3355e66790af6103d06de6623f5f6d6e6cc44ae9c18cbc9e585c2207669f258f8bff291950bb4202a1c9f523953e72033cb1b',
  },
  '0x2f9eeb5d4282710b5d18a9d410a7b8e9d4607f95': {
    request: {
      account: '0x2F9eEb5d4282710b5d18A9d410a7B8E9D4607f95',
      mintLimit: 1,
    },
    signature:
      '0xce7113c00df7384c1e900f9528b5bc2fd56c8f1a8e023cf80f4c3feddf9df6a70302b0a568292c26f07f95248f04b21a62546cc21a3b49889ef1768c3900346b1b',
  },
  '0xc44fd102415ff62769a4e37c70dea27033a5291f': {
    request: {
      account: '0xC44fD102415FF62769A4e37C70deA27033a5291F',
      mintLimit: 1,
    },
    signature:
      '0xc58c851cb71a8312596944684959b532c6a7181da249910f786d62c7f6b8ea8507c46e5f2bcdd3e1d6c33b170cae29a18a5494817b214eba94e97bdd76441ef31c',
  },
  '0xf991acd672d5b344afcb29931af6401ed32e2691': {
    request: {
      account: '0xf991AcD672d5B344aFCB29931Af6401ed32e2691',
      mintLimit: 1,
    },
    signature:
      '0x2bb212e2e0224bef90a92c56b3e475ab8d6bed9d817d85a14107f0373c49ef651ab40ffd6d48b30dafa41f0ded90a5271ed78288f4a2175f9ddedc411bf09a741c',
  },
  '0xa937f63ba1d69fd7e022fd50628b6d8fcfbde52d': {
    request: {
      account: '0xA937F63bA1D69fD7e022fD50628B6D8fCFbDE52d',
      mintLimit: 1,
    },
    signature:
      '0x0823f76a1c05a14dbe2940937d001e52c2c5cae571c698c0273f26b994f184c24e4a2078a16f55e25c10d5d9b6dc6b39e60d8aced635ebae749b8af570927b6b1c',
  },
  '0xbb226555fbb98850273b10b0cf55ad2f99966d20': {
    request: {
      account: '0xbB226555fBB98850273B10b0CF55aD2f99966d20',
      mintLimit: 1,
    },
    signature:
      '0xc40f88b32f5daa8e4fc3654a4eae53dcd3d48f6ecb8ff1620efa414c6e76208c178185a1cdae14519c8dae80efb528ff69b08aa059796baede9eaeb1217cb9581b',
  },
  '0x4e017a192177abdae99250b6a0809dd76c856767': {
    request: {
      account: '0x4E017A192177AbDaE99250b6A0809DD76c856767',
      mintLimit: 1,
    },
    signature:
      '0x2ff927c128cdf5e7ddce1717715eea2bf8c6f1204582bd32532778d512e84f367727bffb4cdca67f6d44b196bd7092fd6b401bfbd289b037ac5bb2c7ea94a4561b',
  },
  '0x8df5f3e4a0688595b02768c37f32424365f36f26': {
    request: {
      account: '0x8DF5F3E4a0688595b02768c37F32424365F36f26',
      mintLimit: 1,
    },
    signature:
      '0xe8c0320929d53cfe890f76358e747292503e6b0dd5b5a58065fe3a1897aa0d8366e01d4ecefb8608afbdc7497b4de965780d861687989cdcb5f165d9eb233ddf1b',
  },
  '0x8dc09f5364a03de8d0da29d6274eabe8930b415a': {
    request: {
      account: '0x8dC09f5364a03De8d0dA29d6274EabE8930b415A',
      mintLimit: 1,
    },
    signature:
      '0x42d0ebfb90cdece42ad6a4dfba6fe825340dc38092b827c786211ca1b758eac264f7378fec86db2bbcd9f0d5c76cb8f8e070a8abdfaaae1f92e02fdb87b513ca1c',
  },
  '0x4e65175f05b4140a0747c29cce997cd4bb7190d4': {
    request: {
      account: '0x4e65175f05B4140a0747c29cce997CD4BB7190d4',
      mintLimit: 1,
    },
    signature:
      '0x7b064da661429cd4f8fccaca6c3099d93fc215954dcac7058ab8b57a816813a50e74413589b43ff9d72d45ae07aa8172d43c3a51528c4a2ffefceab4efb765a81b',
  },
  '0xe1f8afc92644bfe77080d7dcb0f936f578e00f53': {
    request: {
      account: '0xE1f8aFc92644Bfe77080D7DCb0f936F578E00F53',
      mintLimit: 1,
    },
    signature:
      '0x5c059172f1b096b277968bfe1bb4fb192a1fb6edf90d8a21b0348ddcb68ebec42bee5f79232eb3cee6137398109647acd8539092682869c8f0afe640b98814361b',
  },
  '0xf13a51dcebb7d19ac0906544fae4e4095580adee': {
    request: {
      account: '0xf13a51DCEbB7d19AC0906544faE4E4095580adEE',
      mintLimit: 1,
    },
    signature:
      '0x05ec5da05cfc7cad1dba1f8bfdd951de2430fba27cb99b5e245fd68791565a2807bfd71fd1dd6f0e2b93aaf5330703bfd200a2bae314a0535260effb239693791b',
  },
  '0xa53a13a80d72a855481de5211e7654fabdfe3526': {
    request: {
      account: '0xa53A13A80D72A855481DE5211E7654fAbDFE3526',
      mintLimit: 1,
    },
    signature:
      '0x179330204972364b427a0befc6b553a643dda0425a3e4a1ae61e76edabff85840e6249f9c703b61a1201e0f20ff377635bb4fb436334797600e885d6b5e1b6961b',
  },
  '0xcf7841fd47bf33004b07ede874e7afc714616231': {
    request: {
      account: '0xCf7841FD47bF33004B07eDE874e7aFC714616231',
      mintLimit: 1,
    },
    signature:
      '0x2f50298a5b32f874a1c18538e6a3aaf3d33b50217a9909c53d6738058cfd01f908b5cdd11c33e6d1b7cb47e42ba2817e3b4840f95d3e0ed48252f35dd5e8e8531b',
  },
  '0xd9da13de745bfa50ffaafd0a531b92f0511b72cf': {
    request: {
      account: '0xd9Da13DE745bfa50FFAAFD0a531B92f0511B72Cf',
      mintLimit: 1,
    },
    signature:
      '0xfb70895de8a01ed190c0f3e9bffe4efc80bd2c5a036bb921329a737bbb1d5e033f331f8e1fb9480e1b2a189210fc545b662f90342003a7c830ddf3882b83f2031c',
  },
  '0x056590f16d5b314a132bbcfb1283fec5d5c6e670': {
    request: {
      account: '0x056590F16D5b314a132BbCFb1283fEc5D5C6E670',
      mintLimit: 1,
    },
    signature:
      '0x356756bded5c50feed5043b35354726f94e58186b7eef4470884a13bcb48935f23931e6335e285862d96be080e7d98317b6f13def637b22a7421000d2c1262d31b',
  },
  '0xcdd7152eee446ed547d8e8f19f2d83cf94d4c235': {
    request: {
      account: '0xCdd7152eeE446Ed547d8E8F19F2D83cF94D4C235',
      mintLimit: 1,
    },
    signature:
      '0x5f0e093e9a0df275e49302a0d7414271a872ebc9969574f87f48224d9c83f36b77d387f4b9befb67ccd0bcaff9da044acc2afe0b14c7b0f632a1a0aae58849181b',
  },
  '0xd746a2a6048c5d3aff5766a8c4a0c8cfd2311745': {
    request: {
      account: '0xD746A2a6048C5D3AFF5766a8c4A0C8cFD2311745',
      mintLimit: 1,
    },
    signature:
      '0x731ad6b15e97c968cc86723d015fc0b56c7a9004b684b34d4b2f62f7b3be11ba075e83e14e5addd8d662cee9a66860931003aea40df9e8b7cf2c8d23a7a865131b',
  },
  '0xe9b14a1be94e70900eddf1e22a4cb8c56ac9e10a': {
    request: {
      account: '0xe9b14a1Be94E70900EDdF1E22A4cB8c56aC9e10a',
      mintLimit: 1,
    },
    signature:
      '0x4240e59da9d1d923a654c8ebaeeecb0ae6b2f7a1d7488e185f47fa03ba4b392a58a9fef9206ce2b5e192dd7c49d8658aa986580a7aafd519b7e017a23ecc92711c',
  },
  '0x9157b3a15eec097c3fcc13969291d0005473b81c': {
    request: {
      account: '0x9157b3a15EEC097C3Fcc13969291d0005473b81c',
      mintLimit: 1,
    },
    signature:
      '0x3161af19c50eb260cb1abe80328a9e3aae7f0496e979da5815d73ed0d2f22e1e34c0d6221a6f1b075f3d1ad236888d66a57f0b39db234922ba91812640425c191c',
  },
  '0x473d3a2005499301dc353afa9d0c9c5980b5188c': {
    request: {
      account: '0x473d3a2005499301Dc353AFa9D0C9c5980b5188c',
      mintLimit: 1,
    },
    signature:
      '0x2309192ac47bc91a81195efddaced80bf6e88d23dd327f11d2df26daaab9d198218f4041d202a940e99320856ef69eed8d3a9eecbd1a15b49a6d4c79ab93533b1c',
  },
  '0x344d9c4f488bb5519d390304457d64034618145c': {
    request: {
      account: '0x344d9C4f488bb5519D390304457D64034618145C',
      mintLimit: 1,
    },
    signature:
      '0xad5192cec07296c940894138ea78fabee3d092ddd7172d1eaf3e8e71a0045d8750c0fec82fcb0514809c6cba577ef6172f9042372be7a4d662802fa29e84e33b1c',
  },
  '0x3de52fc645a215d841c6891b9cbf193ebc728814': {
    request: {
      account: '0x3DE52FC645A215D841c6891B9CBf193EBC728814',
      mintLimit: 1,
    },
    signature:
      '0x3a52fa1a3e5279bd4e10930025cd8fb35cd8a4792cc6c6d1d319d4188d9f06f266343aecb80cb4cf7e2b554ee09d2767a9892d85a26451a024c14c24480d035b1c',
  },
  '0x854f1269b659a727a2268ab86ff77cfb30bfb358': {
    request: {
      account: '0x854F1269b659A727a2268AB86FF77CFB30BfB358',
      mintLimit: 1,
    },
    signature:
      '0x94e0bc264731e68572b8dca39a937df22988611946152cf24d484cb835e3dd492bb672dbae2eebbae5e6c080a53d551e2de9f5c9a622a8356f8b4aa3d4a303eb1b',
  },
  '0x480baff6aedbdb82292e295570b9678bae15a1a3': {
    request: {
      account: '0x480BAff6aedBdB82292E295570b9678BAE15A1a3',
      mintLimit: 1,
    },
    signature:
      '0xbae64cde35739484f02796efa099d6473bb0aa6c3fe91fb6df26077a3e0bf6b705bcf09dcf38bd9635f02a9cf133d8f897190dc4f0ad3084b7884f4b468d908d1c',
  },
  '0x9cbf099ff424979439dfba03f00b5961784c06ce': {
    request: {
      account: '0x9CBF099ff424979439dFBa03F00B5961784c06ce',
      mintLimit: 1,
    },
    signature:
      '0x2c92a2165b763a472599d38b4f9f97f49a30a4a40f016d4ba9fdeac6207718d36c26724e7d96cfc19026690ff49eb636b319b828cd213d2daf25c3b826f56ca31b',
  },
  '0x86fa4e9b52949f6d8374eb124c22e3108c618489': {
    request: {
      account: '0x86FA4E9B52949F6d8374eB124c22e3108C618489',
      mintLimit: 1,
    },
    signature:
      '0x577d6b142696e78ebf1b9c38ee58ed8d6cff69a56596f06be87a41b60e30ce57397f9f11a2a0655988ff56f0392c862b827a72b94ecdf980c432f3d7b3fca3dc1c',
  },
  '0xd6d6e7697d0e7865c5b6402760de3a9ce2449727': {
    request: {
      account: '0xD6D6E7697d0E7865c5b6402760de3a9Ce2449727',
      mintLimit: 1,
    },
    signature:
      '0xcc1f344855becc86463d193b54a0499eca168ddd80a1c6046617eb77251caf0646f70fba23b0b7c6bc2d059fb4bcf0983712106ce147891fbf5efd8cccdfec5e1b',
  },
  '0x3451c0a99b4c8fc70db73db5b30a109d57140804': {
    request: {
      account: '0x3451C0A99b4C8fC70DB73dB5B30A109D57140804',
      mintLimit: 1,
    },
    signature:
      '0x8a2653733229a5cf3549aca018216a72a5b28acc1c0a7da393ef3082366072a81e1b29c0b676f2e8d2dd3a221b434bb9aa646457339b895928bb16e08e781a871c',
  },
  '0xf9f7aca75cf438ce22184331a66591a02df3a216': {
    request: {
      account: '0xF9F7AcA75cf438CE22184331a66591A02dF3a216',
      mintLimit: 1,
    },
    signature:
      '0xdc48ca15c1ae207433eff941d27009081e9e34bc5b319b28bf8f466ca77e43cf1a59677ee427689f445350a88aeb6297998fc29be95308d712c028fec7d6f2c11b',
  },
  '0x93d2f4f3a0445aa808c99cbd6ba05236a895379f': {
    request: {
      account: '0x93d2f4f3a0445AA808c99cbD6bA05236a895379f',
      mintLimit: 1,
    },
    signature:
      '0x3bc0c2692bc971d2934a75dda4457df11f29f4edb1867994c65bef59d57eb0671fc2a803bcd9eabaf089770f3da7a2ac5004ad082a739eb280d21f65184a50911b',
  },
  '0xb2047abd38e95e1e4cb2addf4df4610ccd2e98ce': {
    request: {
      account: '0xb2047ABD38e95e1e4Cb2AdDF4dF4610Ccd2E98Ce',
      mintLimit: 1,
    },
    signature:
      '0x2e2e3bb73208b4fd194b3f9c58f8bd17c8a7f712b65cbe5f138bbfdea2596b9e7eac882055132a6b00a731781a6013f03afaa4c1979dd5146d47d70a935c6c111b',
  },
  '0x60753e8622a6cfea1df449a9625a9778bb922b72': {
    request: {
      account: '0x60753E8622a6CFEA1Df449A9625a9778BB922b72',
      mintLimit: 1,
    },
    signature:
      '0xfc5ecbebd079f850e49335d0bfc09a23f0b92533b5f12e7444238db2986c49f4524cfe8f13f153978e682e6dbcd0e6f9595c083cb400a95b153d2a9a816f95cd1c',
  },
  '0x41c6866f6a0f06350d5ca290d5c00af1b32a6b12': {
    request: {
      account: '0x41C6866F6a0f06350D5CA290D5C00aF1B32a6b12',
      mintLimit: 1,
    },
    signature:
      '0xa2769e6cb6a0a3c8846a5c0685cc9cd20177f36200cc9ebc43a41fb0fc112e093f4a160877cf83d6011f4a6e946ceb50b419d54f70c28897ad035ead7a4963141c',
  },
  '0x92d778a1b9ddfec88c86475c108ea98ea66fee4c': {
    request: {
      account: '0x92d778A1b9DDFEC88C86475C108EA98eA66Fee4C',
      mintLimit: 1,
    },
    signature:
      '0x905d2cb1ff34f0618488264c45e603fd5c65313b3a34ad222cf80f0aa5b9c337315b85a7ec2fdbc6096236efd08f36327422c27ed7b4862abc3592ffd3d54a9c1b',
  },
  '0xa0f2e2f7b3ab58e3e52b74f08d94ae52778d46df': {
    request: {
      account: '0xa0f2E2F7B3Ab58e3E52b74f08d94AE52778d46df',
      mintLimit: 1,
    },
    signature:
      '0x8ceed98b96348d95537cda6662029c6b9b233f3c643826e5a59812920f3a76af7db702fcb93c548bc441cff858fccfa182b685c61fe679b0db832436e8d8b4fb1b',
  },
  '0x9d0307dc8dc7ed9ae12f820ee48a469f6554a032': {
    request: {
      account: '0x9D0307dC8DC7eD9AE12F820Ee48a469f6554a032',
      mintLimit: 1,
    },
    signature:
      '0xeecde4ca89b850fa602d06386e1000c4aaeda61266fb1d6f3efbc4643b093ddd2be6fa0be4d6ffc53fd1e63ce3da8931c459e55a5b24fcc65b07b2e13b7a3ba91c',
  },
  '0xdaf8667875d27ebd1bf70c72f135492260e3037b': {
    request: {
      account: '0xDaf8667875d27EBD1Bf70c72F135492260e3037b',
      mintLimit: 1,
    },
    signature:
      '0xb5aa230a5c68c179fd8a2adc75dd49c7422f195aded9152f97fd51812b38ab6350797962069eb87416cfa1f024c8d754b6b82569659872d750b62bb5fe236d6a1c',
  },
  '0x243807b03802c05dfc84003a6b01aa41d7d0a8b7': {
    request: {
      account: '0x243807B03802C05dFc84003A6B01AA41d7D0A8b7',
      mintLimit: 1,
    },
    signature:
      '0x3288645cdac7430e3836607b76fcc0c823c9b2c17dacc57c401e07f7f2cf14733cb414cb4f3e54671031b48c29f24f1555ba5f3e846fbd96f192b0eb53f9ed6f1b',
  },
  '0x183d0567c33e7591c22540e45d2f74730b42a0ca': {
    request: {
      account: '0x183D0567c33e7591c22540E45D2F74730b42a0ca',
      mintLimit: 1,
    },
    signature:
      '0x4a65f0a58f28c6fc3b8089b86dbbc7152262105d29ab7f849d8bd18b3852c987024999eb888301b2fab5efeee3578f8876c961c133f858edc35a865221b08dd11b',
  },
  '0x54df9eedd4bca708c98a4b2f35e979af9452cfcc': {
    request: {
      account: '0x54DF9eEdD4BCa708C98a4b2f35E979AF9452CfCc',
      mintLimit: 1,
    },
    signature:
      '0x07f36a6106da80c4c87fa9c920e9174ed98b698558f63ddb6e660c4adace9c5014f3521cbf125cb09ca13d1456cf2ccc84e6e63f3b0895ca2a09cde9f77a33c81b',
  },
  '0xe11199046918c186bcc5d865ae5b1d20530a174a': {
    request: {
      account: '0xE11199046918C186BCC5D865Ae5B1D20530a174a',
      mintLimit: 1,
    },
    signature:
      '0x28e93bbe8c5f702b46bda73f548c951bb096e165f264ab64cbfe8c593cc33d0865eb4bbc3bf20287a92ebe7080cf75f765829f257f20d2f56280d6603ff5797a1b',
  },
  '0x0f6645d297e47e3b59f48f0cb1be7df4c68baf99': {
    request: {
      account: '0x0f6645d297E47E3B59F48f0CB1BE7df4c68Baf99',
      mintLimit: 1,
    },
    signature:
      '0x134b9e973761cab32ed2be77fb2e87bad9a75c50e9e3a7a246f3d482cf88666e4ba4d2b0b14d15efea2109d3f1b58c9383acaad4e3afea596b489c7ab2a1188e1c',
  },
  '0x26be5a29181a8eb74fca2d140486e50b1472a8d4': {
    request: {
      account: '0x26bE5a29181a8EB74FCa2D140486E50B1472A8D4',
      mintLimit: 1,
    },
    signature:
      '0x9da48966787ebf4a71c28f8fbd2e6ea8d8546172409d96d9f868a129a39ea4f110312561d5e5242dbddcdc10e77541aa5a450af082289846a8319d686ceb34fb1c',
  },
  '0x7ec7b375834ef33a1a677503d5065651ee530975': {
    request: {
      account: '0x7EC7B375834Ef33A1A677503D5065651Ee530975',
      mintLimit: 1,
    },
    signature:
      '0x7bcacfa0f541d3e970505459e013c1af2bc3861c187e04aa17df58f31f2f7ad066a0efc1f9d0831388f78644a0d6a422c64dd620d1d30aae4f7671ea6ba2b6171b',
  },
  '0x7bfee91193d9df2ac0bfe90191d40f23c773c060': {
    request: {
      account: '0x7BFEe91193d9Df2Ac0bFe90191D40F23c773C060',
      mintLimit: 1,
    },
    signature:
      '0xa30d8aa5ede858dc7f2963ba882e572b7842773e5a6c3cdb621dccad7ce6515b1a3671e2be471d85f4ef8f0fecfa2019cd3219a671388910f0ed4cc529fd02ec1c',
  },
  '0x6b352735ee8f1cebbc4fa438fc3fae0d42d337c3': {
    request: {
      account: '0x6b352735ee8F1cEBBc4fa438fc3faE0d42d337C3',
      mintLimit: 1,
    },
    signature:
      '0x34058636d1dfd07b0b89a16336f9c9afefef572de7405d2104b4f4e29cc0f1491c0872a1ea95a82f1d8f68ddaa099c017eccc3cb5c0e3d5b7cc016d6df7372001c',
  },
  '0x615f73830e3dec57c58395d9e9cf2b6e54666a95': {
    request: {
      account: '0x615F73830E3dec57c58395d9E9cf2B6e54666A95',
      mintLimit: 1,
    },
    signature:
      '0xc2a8f95187925c7133ac3e8272d57a283fc178ab019d7654ce0bb3b844fa1232002e7cd2719521c298d6d93f06ea3b53a65119dde4e29a7924a1cabec122a6a21b',
  },
  '0x3a8315e25378ce9fc0d3279b26ec0576de52c0ff': {
    request: {
      account: '0x3A8315E25378CE9fC0d3279B26ec0576de52C0Ff',
      mintLimit: 1,
    },
    signature:
      '0x07d87d0759053be2701acd28d3c4dc9ec9cced6d3a75e5148c51c1bf7183b0516c00413408ef2161b413f1b3d586c5bfc99acc1fd8e0a90cc698b46a839527061b',
  },
  '0x5c8494ffcfba5ab54f9a812c2f3157fb07a974a5': {
    request: {
      account: '0x5c8494FFCfbA5ab54f9A812c2F3157FB07A974a5',
      mintLimit: 1,
    },
    signature:
      '0x9ca44926385acb7e657a83f937c17793dacfffa3600a33789b5a3ab24b1bf54611e048c163ac2f73df14eaa353526488c6f02745f69a831c16ca9e06ea1fa51f1c',
  },
  '0x3ec6732676db7996c1b34e64b0503f941025cb63': {
    request: {
      account: '0x3Ec6732676dB7996c1b34E64b0503F941025Cb63',
      mintLimit: 1,
    },
    signature:
      '0xa4c9331693f7a2e23dacb63f0335531554596051844df8887e9cf53d81c5022a2ced5791f5fc551efb174157734634a77243a89727563b9209c4ef1d540cf7601b',
  },
  '0x9f7ac6a8a4984e7672b71247e173a5c26e75b810': {
    request: {
      account: '0x9F7Ac6a8a4984E7672B71247E173a5C26E75B810',
      mintLimit: 1,
    },
    signature:
      '0x4eb48de005c489805ef4678f0f5b51737a72172e023ad021b6932025e52050a3388ba7437b7455466ae0fb399edb2148f3a4fd0eff116be8cdb9c0d350713fa81c',
  },
  '0x726f92b8fa4bf441c033d557572dfe1d80753a97': {
    request: {
      account: '0x726F92b8fA4bF441c033D557572dfE1d80753a97',
      mintLimit: 1,
    },
    signature:
      '0xd928b23ad81bcd73e8abda75b2dc5bb6beb44612f1281792a3fe16d4f9498f1e3bccfd74cb1d0abd9e8b2987e0a1ce9e097b5cbd76a59e8437d554b807f661b91c',
  },
  '0x839f654749f493f5407bde26556e5052376f144e': {
    request: {
      account: '0x839f654749F493f5407bde26556E5052376f144E',
      mintLimit: 1,
    },
    signature:
      '0xe1f0bd1b5494e83ffb2481b3bebf0eac018bd34e4d520331ddc4a1d8a03fe7f35a770771bdb2a8849108dabef3df222aab80861df2215117b336d0f71e9f9e091b',
  },
  '0xbf5f7f1e9029a69de45e59e06170c5094ab821a3': {
    request: {
      account: '0xBF5F7F1e9029a69de45e59e06170c5094ab821a3',
      mintLimit: 1,
    },
    signature:
      '0xaf4003306f242aaeedc0c5ca7290b8bbb8e7d8ccfa988a5c882bd6c38bbac3c02df62a1311a23b5b2ce70f808c0a2b4ec761974e8c82c1b79fdf72e206dd37061b',
  },
  '0x19af41b762ecdaaa98b8a1cfb272fccddd956c8f': {
    request: {
      account: '0x19aF41B762EcDAaa98b8A1cfb272fccDdD956c8F',
      mintLimit: 1,
    },
    signature:
      '0x12bff4ba92a3714d8547d4b8293b08f4a345b858bfe76b0153269535a7cb696677acaa4a34ea69349fd51b94efc12435912ec8526782208e4bf57e5b096a63a01c',
  },
  '0x4bf107d6d2b0b15f3f78f35600932df48509da2f': {
    request: {
      account: '0x4Bf107d6D2b0B15f3F78f35600932dF48509dA2f',
      mintLimit: 1,
    },
    signature:
      '0x29467bf8329c2aa2c5c87cc41e7fe462169c15bd3dbcf7312617002d6e04094d5aaf26ba479e545d9cdf47ce9ca579ab3715d6992b422a10230a7baca5a4a24e1c',
  },
  '0xd50cbde55f8da4e8cf94f38deae2c722a5bd156b': {
    request: {
      account: '0xd50cBDe55f8da4e8Cf94f38DeaE2c722a5BD156B',
      mintLimit: 1,
    },
    signature:
      '0x16f1d91ced91b3fe8f171d9bbb3a82e5fc9b4cdc711758a05cda4f2c6f7b3c0043acd59770748c3245dac2333f885227580eea09339fce4ab107872576eeb8cb1c',
  },
  '0x61329adc9362b5906a6e098ac01616a70fe57080': {
    request: {
      account: '0x61329aDC9362B5906A6E098ac01616A70Fe57080',
      mintLimit: 1,
    },
    signature:
      '0x2144c6f663bf4da754b1f40913f913ee04ad594277b004239c941a9a7028388b270d8accc3f53ca3b9a275b70d3c78bbfed80ac1a0fcd038b2a0da04331d56ee1c',
  },
  '0xf39e8cde1cec9ce52fe33e6e0bd5b6c207d6083e': {
    request: {
      account: '0xf39e8CDe1CeC9ce52FE33e6e0Bd5b6c207D6083e',
      mintLimit: 1,
    },
    signature:
      '0x29c24c1bc9bc90958870c6ffc4b0bb681fe044b565a6d0ecbfd3c118d538b74c12e298240f0b8f5da8513723bdfc47a76bf8faa20af3002fabd0f3d7c5cc0e881c',
  },
  '0xd87de515c5272f16bbd007356e697faa35e78af5': {
    request: {
      account: '0xD87DE515c5272F16Bbd007356E697faa35e78aF5',
      mintLimit: 1,
    },
    signature:
      '0x4e73fbce7dde6d78d65f9e3c0aad3c46bb45ff5a2d9c411565248a7a2447a632767d16b94e0e29cfa7acd159b1d8f1581fa20057d2adf021ca927b07a6aba1051c',
  },
  '0x728ac133a5c9250a61f7b27abbe7a823e786ff66': {
    request: {
      account: '0x728AC133A5c9250a61F7b27AbBe7A823E786FF66',
      mintLimit: 1,
    },
    signature:
      '0x700ff4c27f6c873f1b79cdaf5bb2d99222f24f730b1ec149e8f084aa54d62ab06677623f0d3176b866f2b8fcf1b7c52bf71466a290aed8b31bb692635d06194a1b',
  },
  '0x88888f31f9ab8e195b7b05d717f4abaabaef33a0': {
    request: {
      account: '0x88888F31f9aB8e195B7B05d717f4aBaaBaeF33a0',
      mintLimit: 1,
    },
    signature:
      '0x25e201654e8e161062d4c5a4fc1ba7f140685ebc6043f30c0a17c4f4c80221051e9745f98a6d6a711f0caff5ba11939c0b55aa3c00ea94277f0f5ca1c9b665641b',
  },
  '0x1650683e50e075efc778be4d1a6be929f3831719': {
    request: {
      account: '0x1650683e50e075EFC778Be4D1A6bE929F3831719',
      mintLimit: 1,
    },
    signature:
      '0x18bba4ca1d8fa3bf3eb682f2e2e1b99ce3e43ca8fce52fdd221cc051eec65e0d47a038673dff79cafd0f238db8f78346b2e6afcaf203aa2ee336f4d4219660e81c',
  },
  '0x044a9c43e95aa9fd28eea25131a62b602d304f1f': {
    request: {
      account: '0x044a9c43e95AA9FD28EEa25131A62b602D304F1f',
      mintLimit: 1,
    },
    signature:
      '0x38a5aa8ea71e3b505ac67d2473603f346c93b2f4d887637909b3c7c2bb873ca058a37409625d76cc60bf609cd3c069d447e51059b46feaa8a346cb70a560c7c91c',
  },
  '0xb92aab5edde1deb1805493d7aa48abd744c465e1': {
    request: {
      account: '0xb92AaB5EdDE1Deb1805493d7AA48ABd744C465e1',
      mintLimit: 1,
    },
    signature:
      '0x4242056f2ee4f6e28bb010f6d08d6a2d996d1013474eeb484238e642335b1b0f40ab6b19570ee9cf375575b65a556288123b7a3cbe262f44192641053459f6041c',
  },
  '0x9c22af95b3c7c12bace827bb84474830d3bc3cb2': {
    request: {
      account: '0x9C22aF95B3c7C12BaCE827bb84474830d3bc3cb2',
      mintLimit: 1,
    },
    signature:
      '0xa91326a7d755b6509a4aba90f3fb99e2a577a162e3d7c56830416a6c2107c4c958e212a4e514c2d03bdb749689863a689035f0e199dc2951081de32d5be18bb31b',
  },
  '0x2e139f986a209a0c995dc239965aa3b20721d846': {
    request: {
      account: '0x2e139F986A209A0c995DC239965aA3B20721d846',
      mintLimit: 1,
    },
    signature:
      '0xaa05f5b5b826d3fc444ae8b172ea9f870d497bda8abd80404ad9e2d6ad2cd89055172f5869451e7af65b2101a1478c538f3f8f96e8ffeee42c4dc6f1c0a4777e1b',
  },
  '0xff2ca5419e4fa8d5794228aa7861bae29f9d510d': {
    request: {
      account: '0xff2cA5419E4fA8d5794228aA7861bAe29F9d510d',
      mintLimit: 1,
    },
    signature:
      '0xdd5f926fe2bfb7925b83ef7c45a270008cdc794592f477b6e598edefc3ecd498692f8622838f2dc19894516952947fcff2ccb94cfb690edb45707dc3b33c1efd1c',
  },
  '0xee1e33029c2104993e4536be502990284e77080d': {
    request: {
      account: '0xee1e33029C2104993E4536Be502990284e77080d',
      mintLimit: 1,
    },
    signature:
      '0x630dc5400aa74c575e2242101a4a27a966b929111f6003fd2cc6a7923378cc44435401786c6606b7e16c2f689345f1be5877bbb82453c531870f618c4af22ba31c',
  },
  '0x9f08e57ef146a2053f6d741d18b19f4cf37da739': {
    request: {
      account: '0x9f08e57eF146a2053F6d741D18b19F4Cf37DA739',
      mintLimit: 1,
    },
    signature:
      '0xda59b3bdca08cc904d29851635f8c645ac5cce751cd8e440fbbff03a878e63a54215983157d6b5d495c5c2805444288b6e44e17d3f5a8fff38736d03f3d6beab1c',
  },
  '0x891a144965c72c50201bf06f851d3a25d1d07946': {
    request: {
      account: '0x891a144965c72c50201BF06F851d3a25d1d07946',
      mintLimit: 1,
    },
    signature:
      '0x8ad69f23e54d2a2761f4a8c6bff6190d010239797e4104b39da797e3bb678a0b3209850035d21051fee7e6bce3eb4ee7307b293fc55fa1cba05d66388ea2d9a31c',
  },
  '0xf9aeb52bb4ef74e1987dd295e4df326d41d0d0ff': {
    request: {
      account: '0xf9AEb52bB4eF74E1987dd295E4Df326d41D0d0fF',
      mintLimit: 1,
    },
    signature:
      '0xa19df1190c0b411d420dac118a4a290d2e334dda97cd7182804ac1a5d8a2953f2ea3f4138d2cde1bae4b39600132a4b8b082a917de5df3e0fa51f19a98b8a9471c',
  },
  '0x21c8dc59f2e9a11c4c1a0c310641968132c6b1be': {
    request: {
      account: '0x21C8dc59f2E9A11c4C1a0C310641968132c6b1Be',
      mintLimit: 1,
    },
    signature:
      '0x6328b8aebc994fa81e6d05b7cb62dc70afb894d2117b4dcd80eec8f5a4ae98472697569b7ada76e1bf44c52755e709bc249380ce3eb91d38342ace161bd0b15c1c',
  },
  '0x29af0036eb56ae8273815b250acc0c49eaf5c3da': {
    request: {
      account: '0x29AF0036Eb56aE8273815b250acc0c49EAf5C3dA',
      mintLimit: 1,
    },
    signature:
      '0x9fae6fe5086f6c278969c15723fdf182e46614c9404a622e8790af9c212c88d507cd946cacd980b82e5e17b18970504528f9dd521d992b8f22aeae08ebfdac511b',
  },
  '0x067c98fc22566300a927fedb347f86d56ae7e2d6': {
    request: {
      account: '0x067C98Fc22566300a927FEDB347f86d56AE7E2d6',
      mintLimit: 1,
    },
    signature:
      '0xee884805bf8dd91e6df61f9de991347403e6c3ba8bd3c7b2a9a98c4f7e0efac17245587a8e4fd0362e0f4b940fbea28f053e8ba047bfbb2e684983519b31d72f1b',
  },
  '0x4dcbb1fe5983ad5b44dc661273a4f11ca812f8b8': {
    request: {
      account: '0x4dCbB1fE5983ad5b44DC661273a4f11CA812f8B8',
      mintLimit: 1,
    },
    signature:
      '0x19a1836444af66f0e5b511686e574384804f333c5b784c0386be75f17e5dbf514f29ab69f971f8df2f1732d57c559606b99977487154519fbaf65bf4e2776bc11b',
  },
  '0xe83e1208b2a5308c57ffc3256bd601d887d5d337': {
    request: {
      account: '0xe83E1208b2a5308C57fFc3256Bd601d887d5D337',
      mintLimit: 1,
    },
    signature:
      '0x6ee43cb0ee29aeb8b760ebbd876b70c23a56547eac65c667b4cb508caeb65f8d14bfc4781168d358b4d64b165001710ac5318a1d9c62e32dedfcf703033dbdc81b',
  },
  '0x24a44aef48aeb27c7708dabfccda14b41fbf0ae1': {
    request: {
      account: '0x24a44aef48AEB27C7708DABFccDa14B41FbF0aE1',
      mintLimit: 1,
    },
    signature:
      '0x37f64d383156b780150e1148c576ac6b9bfefad49bc1a700ff7f51b516b6b0bb3f709fcde5c9e667499192670c2eba2597c92a688f2f3c78474484c3474b2c4d1c',
  },
  '0x197a38f6a8a2aeeee709940bcb8d8fd8f2f0b4e5': {
    request: {
      account: '0x197a38F6A8A2AeEEE709940bCB8D8Fd8f2F0B4E5',
      mintLimit: 1,
    },
    signature:
      '0xf1cbef0b71babf621b5bf1154c08746813782bcfbf7afe29c1ec3ef1764ffadc237ce40d899f329a6cf2b85a393086d9423b2d8df3ac497f8d59a0265f1efb041b',
  },
  '0x004397a3573c77bdf6c422314e88cc0a805a7e71': {
    request: {
      account: '0x004397A3573c77bdF6c422314E88cC0A805a7E71',
      mintLimit: 1,
    },
    signature:
      '0x6d24427a9764e8aa3b6f5351b029d82282d391e5fc89d598a0168a4ac3aea6d470642a9714ccd8d1f769d0d33fff243777996c161ebe9399460bb0afd045800a1c',
  },
  '0x01226e5b70a19c00d2913f3e70ccb2acbf87b504': {
    request: {
      account: '0x01226E5b70a19C00d2913F3e70cCb2acbf87b504',
      mintLimit: 1,
    },
    signature:
      '0x2f1ce6648855a09380cb1c7848332d171b8a27be169d8a01a01a45c3fbf06ca04d1a676d73d467978fee39fc041bfc78cad1c50b1deeaa8ad0b0db18390f70591b',
  },
  '0x7173ce51d1b494320229e06bbdbe0da258b9b547': {
    request: {
      account: '0x7173CE51D1B494320229e06bbDbE0da258b9b547',
      mintLimit: 1,
    },
    signature:
      '0x671fe850aa2de55916e992d617f2fe9b0ee86d614debb91a1b87cf8586238f00283c30a9bdbda87258e0b6f119fa7cea26b8a348cd6f7650719fb536b5b660051b',
  },
  '0x28c01b64f2492d82fd58feecf8c71603c769bb4a': {
    request: {
      account: '0x28C01B64F2492D82fd58FEECF8c71603c769BB4A',
      mintLimit: 1,
    },
    signature:
      '0xeee5ca0d3f5308ce8b7ec1cc783991f90193432160421118d84dcf7c7a689c524c0ee40eaaa3da79498609d66b8323bc5f94c8b393ae2c0d22e4ccb1597fa25f1b',
  },
  '0x76686847a10a6d88f3bc890b4f7ab3dfad2e57d5': {
    request: {
      account: '0x76686847A10A6D88f3BC890b4F7AB3dFAd2E57d5',
      mintLimit: 1,
    },
    signature:
      '0x622a8f58f42d19d082df45ef2e30c083549ffc91e61e2b4c742dbfb5cbb09f1c649b12bdbbb5096851bbfae3f5985e2b966880777efda00f2d15e7f345e91f361c',
  },
  '0xcc1b35ee3eb7e301decf81d3be1a202dfa0332fa': {
    request: {
      account: '0xcc1b35eE3eb7E301DecF81d3bE1A202dfA0332Fa',
      mintLimit: 1,
    },
    signature:
      '0xd7a3be4e31035bf3e35f9ad2884d653f975e88c2bb4109bb02fe91b89361ce281a2cd5c8a4867e2d980393febefc4afd11b1fc22bf64ff6121428e8a6fd4f3fd1b',
  },
  '0xc0fcaf4c8c39a168d34250706ac960809ef4cf83': {
    request: {
      account: '0xc0fCAf4c8c39A168d34250706ac960809EF4CF83',
      mintLimit: 1,
    },
    signature:
      '0x3540d9976035be66451436be85044e718d01a19e86f69db4cc79d1e815df93760c30b70b6a242dffdbe8782949e6d33afa2e38429e3e245db34cbcf1ad0d96851b',
  },
  '0x1afd3a258b1a9b397a24a239bb70444b478deedb': {
    request: {
      account: '0x1AfD3A258b1A9B397A24A239bb70444b478deeDb',
      mintLimit: 1,
    },
    signature:
      '0x3defbbd3fa0078c173d3e81e2a7933bb200a51e5763564dba635f5d83a35568640b05b53c9f62dda30466b4b7ae3f14319e666bf885bb354a050b305f407dc901c',
  },
  '0x1111111d0236eed213100711db95dbb2db486240': {
    request: {
      account: '0x1111111D0236eED213100711dB95dbB2dB486240',
      mintLimit: 1,
    },
    signature:
      '0xea9b025f758974ea96d3f4255b15281add19fba22ad54c6743987b086614d77200ba27f2e790684cb5543ad2592928fd02293f4dc699f6fe008d0009f6c6e8141b',
  },
  '0xa8690dfb2fd8c3cee0dcf08443df05296768f949': {
    request: {
      account: '0xa8690dfB2fd8C3Cee0DCF08443df05296768F949',
      mintLimit: 1,
    },
    signature:
      '0x5ea298b84c75ea60fd588f22924c9cbd6d38f67632fb682224c4980a4c2c2e7044e3dfd127fa3fae3f704bfaa3862ecae50eb49bf891cac48f06d76f4e9c03f51c',
  },
  '0xc9d83ec726f2c44d3cbdbd76790ae2f2e21329b9': {
    request: {
      account: '0xc9D83Ec726F2c44d3CbDbD76790aE2F2e21329B9',
      mintLimit: 1,
    },
    signature:
      '0x7e7104acf9961f8904661573194e621d022267caf1ab584f915f1a6574bd37b83e374a660fe93651a47570f3e75dfb61f76566434464bcf5c05d15e47d2da22f1c',
  },
  '0x5ca15ec1c088b742db09782da177c3e47dbffb33': {
    request: {
      account: '0x5cA15ec1c088b742Db09782Da177C3e47dBfFb33',
      mintLimit: 1,
    },
    signature:
      '0x2eb2b5e8f8f12a96f9ee7182f615c9b0ce7fd0fa5a453fd6c10eddaa7fa703496ad2f222b220a10834187cad9c2377251124b2c7b153fba3ca4409e146da793c1b',
  },
  '0x800d22f96d0656f511695a342692c21c69690c5b': {
    request: {
      account: '0x800d22F96d0656f511695A342692c21c69690c5B',
      mintLimit: 1,
    },
    signature:
      '0x435ac968688822ec3e03ace3c565b8f0972f39e05aad99714ad4fa0e074ac1901f85475df61de119d967f417acef149d5fc3ce1f9ac69801231979386d427c841b',
  },
  '0x59ab3394089ef8827af881ddc43c54e090e5c95c': {
    request: {
      account: '0x59Ab3394089Ef8827aF881ddC43c54E090E5C95C',
      mintLimit: 1,
    },
    signature:
      '0x7296dc0ff505bb261c4bc3d4d99fe156439c21cca7232ea53af510134c756e9451713bfe57967ed25a65a35c2c6fd5d2689330b80a511d194f105374a275355d1b',
  },
  '0x4284be053786ab7fcb7d89a5271c013250e69e04': {
    request: {
      account: '0x4284Be053786Ab7FCb7D89a5271c013250E69e04',
      mintLimit: 1,
    },
    signature:
      '0x0f858057956434ffa236e44f72dc7b497210ce02895ad10b1a7e8b9fc2b5ccdd7c987c1924bc94d9ef16b05227f36ad9dd57f4cb98599de0348912cad42d5a261c',
  },
  '0x360dfc3e97c795a14d421278ecc32af8f953e175': {
    request: {
      account: '0x360dfC3e97C795a14d421278Ecc32aF8F953e175',
      mintLimit: 1,
    },
    signature:
      '0xd742b1b534931d7c1fad5426e17cb00849f09e218639cb1895d7fffd2251a1e331a6c11d976851b5c296b3d34ef449ced4115affda3ab21c79841c0e75234c841c',
  },
  '0x07f82cea5770723207f8544a00023deb8968755e': {
    request: {
      account: '0x07f82CEa5770723207f8544a00023DEb8968755E',
      mintLimit: 1,
    },
    signature:
      '0xca26ea82cceb3ec4b651d429a3c675d10e6c82a567a9c5cd5134d9d08a97d9386dc149d9e211a14941f6955ce0d722f748e07769b444f2c79efffd60b327b0031c',
  },
  '0x142a249972752c39b5796d6549ff5034b8f15e07': {
    request: {
      account: '0x142a249972752C39B5796d6549Ff5034B8F15E07',
      mintLimit: 1,
    },
    signature:
      '0x4b653e1de8931eac9ecd44cb853fa016334883c0f7f0d8b844299addaceebac1532694922f239de3de4c22b857006d8ffa6336a1f95f62edc22b0c5001c75fe11b',
  },
  '0x626d9086883e194b4c80d6526b0428a641a85147': {
    request: {
      account: '0x626D9086883e194B4c80d6526b0428A641a85147',
      mintLimit: 1,
    },
    signature:
      '0x4594bfc82828beff2fa90c5ad2592d90cf26dce3c1b10870663a6435b65eced95f9733c0dc524ad9fb0667980eba82cc1de58f67db0e1aec7f7332ab9bf882f61b',
  },
  '0xfcc64cadb9b3deaca3a196bc33613b8df80a9695': {
    request: {
      account: '0xfCc64cAdB9b3dEAcA3a196bC33613B8df80A9695',
      mintLimit: 1,
    },
    signature:
      '0x5fa8ed39e977917a66a9e5b812fb313e6320e34f0749fffffb4e6e11a86c835e08d07f55ee8a800e46a888344aed7ab7430d8aad921d47fe5a241c34c39440631b',
  },
  '0x46633b491c0dd7b245f47da22855f33fa20a4e06': {
    request: {
      account: '0x46633b491C0DD7b245f47dA22855F33FA20a4E06',
      mintLimit: 1,
    },
    signature:
      '0xeefab988d13566ad9b7e3f5ef6ae03e05e8ffa81e08024cc326a2ee5cb84869344fa9b47cf9959fcf0d7987383418d67cd06cb5104ecb6089ff912d976b5addc1b',
  },
  '0x1a3c4e9b49e4fc595fb7e5f723159ba73a9426e7': {
    request: {
      account: '0x1A3C4E9B49e4fc595fB7e5f723159bA73a9426e7',
      mintLimit: 1,
    },
    signature:
      '0x98456be44a180c575b7e5eb8ffcd6da52b9441f8a371864fbd0b62b59067b04e13abe997ca42d2b49fba41a4477273cad681cbe09cac1b353fe6e7bb251e36731b',
  },
  '0x7c43a9c3b85619be2f7c0a4d676ecd373f63b73c': {
    request: {
      account: '0x7C43a9C3b85619be2F7C0a4D676eCd373f63b73C',
      mintLimit: 1,
    },
    signature:
      '0x202ef153e40dd3c0380d4bdbfaf24a190f3c13f896892e8370a896eacc578deb034de691a8949f6ecd489710d0557e97353120b4b08bfb09ff7421ba3037bb0f1b',
  },
  '0xe5859cbc7a5c954d33480e67266c2bbc919a966e': {
    request: {
      account: '0xE5859cbc7a5C954D33480E67266c2bbc919a966e',
      mintLimit: 1,
    },
    signature:
      '0xb5fba238a504adda027bc5e94d67cd740375e2988125b6960d12d45a362ed740781ad4112fbaf25ba7cbf8eaf07753c38d3492efb99937d9a980d45f20d475891c',
  },
  '0x5baac7ccda079839c9524b90df81720834fc039f': {
    request: {
      account: '0x5bAaC7ccda079839C9524b90dF81720834FC039f',
      mintLimit: 1,
    },
    signature:
      '0xda5f1d8f0c58af16c9f9e1ddd9e091c8a299d3a773d11e1882c058747fc2d6d220db5d5cd33fcba366a9c9ef0203ec3010f0e6fad0e134249197bde1f62e68dc1b',
  },
  '0x4cd6b970f42b41ee58c2e4b7ab99863d5ae52a23': {
    request: {
      account: '0x4cd6B970F42B41Ee58c2e4B7aB99863d5AE52A23',
      mintLimit: 1,
    },
    signature:
      '0x4854078662b35cc347fc40e3e17ac6ead6f6b7b014ef1b5f78f87c60cad8e4d7590daab65badbc59df53f0f37f53a44bb6abecfc91546f676bb78479aeec77721b',
  },
  '0xa01ad16b4f096a9effa0019f837d81670fd47f41': {
    request: {
      account: '0xa01Ad16B4f096a9EfFa0019f837D81670FD47f41',
      mintLimit: 1,
    },
    signature:
      '0xd49f09e6224834900a19d9b99d9df7a45c6a297bbe20b363ec4bafb4d4189fa76ef9355cfe84b892aaa365ed7c3b771fb1ad6c230fd3ca2cc41c8f478852d3701b',
  },
  '0xf9b9208a194deaa9337e596d0a181fdc43b6b6c3': {
    request: {
      account: '0xF9b9208A194DeAa9337e596D0a181FdC43B6B6C3',
      mintLimit: 1,
    },
    signature:
      '0xd8acaa7345306a18ac3c940f30aa52c469205448d71fae3c3f717a7d7ee0dd6b5b259e6efd3e96896743e5316927e8ba456dee568597f8e289cf6db430db1d761b',
  },
  '0x204e5dfe98f0ec982ffe4435d2d5b0cb3d64cd55': {
    request: {
      account: '0x204e5DFE98F0EC982FFE4435d2D5b0cB3D64cD55',
      mintLimit: 1,
    },
    signature:
      '0xfc479f73eb6c1b07273a01a8a5c790ecd55ec9c4d1dedf0402798c620c0314c245fcd8f830471871a4e5de0312e53760cab4a18301c8638664c414b424b310501c',
  },
  '0x975a1370af7c0561d5377ed5d8b37f31fccab847': {
    request: {
      account: '0x975a1370aF7c0561d5377ed5d8b37f31fccAb847',
      mintLimit: 1,
    },
    signature:
      '0x2f408b820b0faa5195c69422b0c0fda2db34752eae332c5295821bc96e9cda7905908b048216e20768768c412f802c6b73cb6308a60b550ce9c927524deb535d1b',
  },
  '0x5be7f89739f4a1d121c7171abee2ba9e1d78d370': {
    request: {
      account: '0x5bE7f89739F4a1D121c7171AbeE2BA9E1d78D370',
      mintLimit: 1,
    },
    signature:
      '0x60cd6772539380965ea421353e73915946c406a9c3d90ca293c120be70091ee86f098b35cfe9ad7951bdc6f050b8e2c90fb071ab0a805fd8b53ce40bdccf199e1b',
  },
  '0xcbd6b0dee49eea88a3343ff4e5a2423586b4c1d6': {
    request: {
      account: '0xCbD6b0DeE49EeA88a3343Ff4E5a2423586B4C1D6',
      mintLimit: 1,
    },
    signature:
      '0xf2f7dd8783bde1a8c3f25f37fd287854e4513870db0354268be0dff8e45d28246d114f7a612449780c2f7a165cfab14361f489af59760873cbb0613bb0dc681a1b',
  },
  '0xc47fae56f3702737b69ed615950c01217ec5c7c8': {
    request: {
      account: '0xc47faE56f3702737B69ed615950c01217ec5C7C8',
      mintLimit: 1,
    },
    signature:
      '0x84c60b5728fd4ca45a118bbf09910bcd250805e57dcfd6aed65d58f1ed049eb82aa54b1ad0593ba0d67786569f869e31fe866dd5dff88a4df04919754c2c852d1c',
  },
  '0x07e2024abc1d20606f9a78ef9ed34cf9f5221222': {
    request: {
      account: '0x07e2024abC1D20606F9a78Ef9ed34Cf9f5221222',
      mintLimit: 1,
    },
    signature:
      '0xb1a08b8a409838619dbeb9d3747a6dacf5cc6de6f1760b2f9c12ea3ddbcff9504c85c8a2fc04b0d62f0262be4b1fcd64addec2bf5d168df9a60c38607262bdf51b',
  },
  '0x2061420530725d897721931f3cb15d26d9d4ab84': {
    request: {
      account: '0x2061420530725d897721931F3Cb15d26d9d4ab84',
      mintLimit: 1,
    },
    signature:
      '0xc55bdffbfc49335ff9dd4de4981881477224d030a69ae134cdc276a1b7582a04642540d936bf8f9c6233de6d114e316ee2adac9e5d70488a15248436a601b9421c',
  },
  '0xd3474bd2f558b12e83810ec7d8af14f2d1d08137': {
    request: {
      account: '0xD3474BD2f558B12e83810EC7d8aF14f2d1D08137',
      mintLimit: 1,
    },
    signature:
      '0x09cc3003fdf013fe0a8d82506722a5781dbd9d7a642e9ec4e4256bfcadb96c2b36bb962048944c87fb7733b4c96723ef0485c391dd3533abf7061997a3c0ee721c',
  },
  '0x5948acfff7962d1264da029de90ffab9b100c121': {
    request: {
      account: '0x5948AcfFf7962d1264dA029de90fFaB9B100C121',
      mintLimit: 1,
    },
    signature:
      '0x50d19307021491f0ef53d9028f1ce0aac1e3d2c991191a383df2b11dbd74bc2b6c313d7f246b18e1a1926464f9946b87456085d7dfa80eba75c0173ed9b2f3ee1c',
  },
  '0xd0399766777704e510925c2f078058f8b6086690': {
    request: {
      account: '0xD0399766777704E510925C2F078058f8b6086690',
      mintLimit: 1,
    },
    signature:
      '0xd394da79876c322f830e5989b9869a65cd9212f7b2353142569bd88946cd0f2775cd56d317996a0ea3ca2ed2678a5d231ddff6e1f1c187f898cbbcf942fda0b41b',
  },
  '0x12d91445fd44b73a822702979c553db3f78de40f': {
    request: {
      account: '0x12d91445Fd44b73A822702979c553Db3f78dE40F',
      mintLimit: 1,
    },
    signature:
      '0x10e6d5cd6582572898f43408d3b54091769e85fe51aa4f24dae2a4bdd27844bd467b4d12b2aa227eead267af2662ceb15dca116a0fae3ac2656db50e93294c191b',
  },
  '0xa2be8e05dbf7f060d565d19bd1dcb94305a35736': {
    request: {
      account: '0xa2be8E05Dbf7F060D565D19BD1DcB94305A35736',
      mintLimit: 1,
    },
    signature:
      '0x5239ca123efe69bd9e6b1627ce39200ff436c6f37e82891f84ab119a44c74ee11152e07be58b6747357b31424415cbd168c8b4fd41a85b6d3c8afa7228b2680b1c',
  },
  '0x0bee06fba2660dd41fdb7b950f0592bbcd7f0fcc': {
    request: {
      account: '0x0bee06fbA2660DD41fdb7B950F0592bbCd7F0fcc',
      mintLimit: 1,
    },
    signature:
      '0x8363ebdf8a218a51eadcdc078228ac6c0f5fc7dac0207f04e1d45f0f7c7815e26af5f3abf7629416c3d663ea937185005d2e53596c42a03d9155ef4853c98acf1b',
  },
  '0xf5dcb2a47f738d8ba39f9fa2ddc7592f268a262a': {
    request: {
      account: '0xf5dCb2a47f738d8bA39F9Fa2DdC7592f268a262A',
      mintLimit: 1,
    },
    signature:
      '0x882f6812227c5c00bb2120784a53015586f614cf5301543a7c06b09205bfc726049cd5e58184ef7fdf76162f0d24774c7bbacedd41ec03f4315dbfd13f606f1a1b',
  },
  '0xbe0030b71e9eded2b417f2a459c0ce24a4e8089a': {
    request: {
      account: '0xbE0030B71e9edED2B417F2a459c0CE24a4e8089A',
      mintLimit: 1,
    },
    signature:
      '0xea5fbaa13eb649b3b7d1ec811c4644c72e2374f48f4d1ad9b5f8a93708c5257b5ecc901ef0ee0f5cb68c24a09a1d4201f677ee2b920dbdd46bdbe7f23c604b631b',
  },
  '0x882a5bfcebf03178c4ab9220c12ebe83590714e2': {
    request: {
      account: '0x882A5BfceBF03178c4AB9220c12EBe83590714E2',
      mintLimit: 1,
    },
    signature:
      '0xc28ed5dbd8c37ebb688407483d6a0990927fb018c6f00bbd2ae7f5160917af302b1fcbbd661b71e0f6854dccd29f20c13ec181861b4a476b2bc7286a2957d4ef1c',
  },
  '0x54dc6782d6fc5fc05f8486d365186ff25cc44ba7': {
    request: {
      account: '0x54dC6782d6fC5FC05f8486d365186FF25CC44BA7',
      mintLimit: 1,
    },
    signature:
      '0xb9159ea571344a9fc37439b1b3279dd4c41f0b1c1136ee3dadf6b8bb7cf3a00d3260fd1cdbc1c097c83baca10a019169f5bc1107e4ffe5e1f4eac1d53e1997781c',
  },
  '0x83c475ebcb12399a2f79c815b79316f573eb501d': {
    request: {
      account: '0x83c475eBcb12399A2f79C815B79316f573Eb501D',
      mintLimit: 1,
    },
    signature:
      '0x9cfb5c95592c74fe9c5377af609c49d50ebc5432fe76dbac1c4ef543ed4a95963c5b5d02c4d09fd3e2c2a6365dc366f08b305ce40d41b5cff3960f41e49530661b',
  },
  '0x62fd9fdf99220fe1ea7f034d5bf66e8afed4e302': {
    request: {
      account: '0x62fd9fdf99220FE1eA7F034D5bf66e8aFed4e302',
      mintLimit: 1,
    },
    signature:
      '0xb87550b7de9b92e003f97b1ed7bb7f379fb1184a89294a9d111f0bedf009c2b340288a9aa9789aa13c313945e97ec7bc55401ae8604b4d038c9137e4e6b6bff21c',
  },
  '0x6fc34a8b9b4973b5e6b0b6a984bb0becc9ca2b29': {
    request: {
      account: '0x6fC34A8B9B4973b5E6b0B6a984Bb0bEcC9Ca2b29',
      mintLimit: 1,
    },
    signature:
      '0xc3bdb44334a02463f4daca2607868cf4bafc9dbeb5e1b0911fe7fe1bed1fd10822ab5df28f928bba4411ad8e409694f5c8dda6bfad5e291bec72dbfb602a7f471b',
  },
  '0x3eedb85da27cac4ec83ab5a8a38f18b0edbc7042': {
    request: {
      account: '0x3Eedb85DA27CAC4ec83ab5A8A38F18b0eDbC7042',
      mintLimit: 1,
    },
    signature:
      '0xa284f8ced0ecd288b49786a4294f221d5b7dfc078bcccaf44b2e6c90757584190397ed355d10b8af48624e60d44c00b08141639f4cb3a9a9940330e49b388dc71b',
  },
  '0xba55bdbf959df826da6c35487eb15fad2164662d': {
    request: {
      account: '0xbA55BDbF959DF826dA6c35487eB15FaD2164662d',
      mintLimit: 1,
    },
    signature:
      '0x531ea0f350bfeb3ff248b308331590143733d6fe8bd1f9fb1157e9032c16d4c03d7a166c6d163d69bbbf57f277402dbf2d766cdb7fd08d3ce6404a84126189781c',
  },
  '0x3efd5cdb46c700481f40058341aa220ac2552660': {
    request: {
      account: '0x3EFD5CdB46C700481f40058341aA220ac2552660',
      mintLimit: 1,
    },
    signature:
      '0xbd4c4760de50a50c48e2703e791b5c8ced88e98181e3b6950688a2b157e5887c0d44caf1a486337891c093582b35992d7d929ccb760f8e1b81944138b9e5fa091b',
  },
  '0xd92293daca6bbed57f8cb6d498b48ea93e035e99': {
    request: {
      account: '0xD92293dACa6bBeD57F8cb6D498B48eA93e035e99',
      mintLimit: 1,
    },
    signature:
      '0x9541f98966dbf93aa706cdf55d0666f96fe37ce342415ab91c45af1995a4552174b34b736aaa43c4ddea1beacc657f0620961f513a5e38cd05c2e52897ee8c5c1b',
  },
  '0x5c9e30def85334e587cf36eb07bdd6a72bf1452d': {
    request: {
      account: '0x5c9E30def85334e587Cf36EB07bdd6A72Bf1452d',
      mintLimit: 1,
    },
    signature:
      '0x6717dc4d5e1b3f0a50d1ebcbd29217ee0b16f498fdf5f122568e4ea0983eb8d94db197584e20041e687828626355e1177fcce5dfe77998fbd74983de40fe7bbb1b',
  },
  '0x3cc01e4a88ef223065f85ee5b90f23db987e49c9': {
    request: {
      account: '0x3cc01E4a88EF223065f85Ee5B90F23Db987E49c9',
      mintLimit: 1,
    },
    signature:
      '0x2b2640ad94a16280764ad74f7311952949f45818f00340478e7e34d3fdd1b01e0aacb97641c8988070083db8f6590940086ed2b3c1b0f895af246082ef1906dc1c',
  },
  '0xd6cb7a7b544e24e5160a948a16c45b1096276f9d': {
    request: {
      account: '0xD6Cb7a7b544E24e5160A948a16C45b1096276F9d',
      mintLimit: 1,
    },
    signature:
      '0x41a9c728d389edcc0ea7be6322b72b00c9bba9000eb2d4b0294027d0fbabbaa9557cab76c4baee46e694b1517ab4475afdaefa06c90f1014732dc8a01edacc0e1b',
  },
  '0xf44593dfae05bff213c876a8e1fdcc15a45964df': {
    request: {
      account: '0xf44593DfAE05BFF213c876A8E1FDcC15A45964df',
      mintLimit: 1,
    },
    signature:
      '0xb12107c65347d6c11572615eb6a6576a724f50f21a4ce8f8c1cc254efa3df38201567a6278a5e6416fd710f6e045843bfc7ad3169ba898d617636fb2d56696ea1b',
  },
  '0xe62f16afd28a43e6b010ed719c4a98e4143858aa': {
    request: {
      account: '0xe62F16AFD28a43E6b010eD719c4A98E4143858AA',
      mintLimit: 1,
    },
    signature:
      '0x90c7fdf40fa204f845ad60efdd7d9ec31a6d8f9ba4e33333c6c8b07e9a6f4113159a5a6d0b184bd86496888073db00b4bd39819e9d2959e027d2c50a40c1b8dc1c',
  },
  '0x7a5c085ddb3041dcf61ad28dcf1668c85bfd1d5b': {
    request: {
      account: '0x7a5C085Ddb3041dCf61ad28dCF1668c85bFD1D5B',
      mintLimit: 1,
    },
    signature:
      '0x1e873de603cc824c91dba407ba8bb7e2cb8d69ef939b60b7db80d1f5722244c63f158856de2448ca8dfa8b9bdd7f6496bfd0d0deaa2ab6fc773a1c31a31e06111c',
  },
  '0xf7c78f53637a7908c00da568a83e6c484b528c90': {
    request: {
      account: '0xF7C78F53637A7908c00da568a83E6c484b528c90',
      mintLimit: 1,
    },
    signature:
      '0x395320b58451fc68a3fee72b97b9a61fffa73989c9485f0fd223ae10cbdf598a478ba0db8e5a639d63c540f5b28bf2898537764632f4955d66164d54719bee541b',
  },
  '0x28b8d4f7516a112e2e2fd462293a1c27cde327a7': {
    request: {
      account: '0x28b8d4f7516a112E2E2FD462293a1C27CdE327a7',
      mintLimit: 1,
    },
    signature:
      '0x7dce600e80ba78c6bb8ecbb1778d7a3dac62d8c7eb1faafdc06d52c53f82bc803612c129c218167392a1bbdbd6ae2661a8dbf7091ec16bc8269060dfe6c8d8bd1b',
  },
  '0x72c6a4624e1dffa724e6d00d64ceae698af892a0': {
    request: {
      account: '0x72C6A4624e1dfFA724E6d00d64CEAE698aF892a0',
      mintLimit: 1,
    },
    signature:
      '0x7237617929c04142e01bd4bd841a6ffb11e6177d109715b5251b8043ab37524c5d55e7ab514fda0a0c4c6986de42064f22e94b7a3e9a006d0da1f55def529d5f1c',
  },
  '0x3d268e0b9f94d762f5e31a91ab1f010a5c082874': {
    request: {
      account: '0x3d268e0B9F94d762F5E31a91ab1F010A5C082874',
      mintLimit: 1,
    },
    signature:
      '0xeb27da86de388656f0cbe77c8b53c7e3d53f3ac42a0fbd0d707e8ca0ada8f688480121f9ce9902edbb3ce023b7d7f193f6ed11c08ded2d7ea5c2677fa71e775e1c',
  },
  '0x4f0a01badaa24f762cee620883f16c4460c06be0': {
    request: {
      account: '0x4F0A01BAdAa24F762CeE620883f16C4460c06Be0',
      mintLimit: 1,
    },
    signature:
      '0x855a8b1d2bac23d8120848b18e02a14dd8adc4454361247aad5654216568200463c569d88386ff76e5caace2d39b51d21e36fe3b4b4418b04705820968cacff31c',
  },
  '0xd9953cc4af128612fcf8c88856afd75fee8b8e04': {
    request: {
      account: '0xD9953cC4Af128612FCf8C88856aFD75FeE8b8e04',
      mintLimit: 1,
    },
    signature:
      '0x5ffad7da2bff490d31d49e1f9520b54e419ea365c8338d19a153fd3c7d0ae49c024cca5e753943bccab0792af812c110bbd912e16da38eab0e5a9b7804c642f71c',
  },
  '0xe4edb277e41dc89ab076a1f049f4a3efa700bce8': {
    request: {
      account: '0xE4eDb277e41dc89aB076a1F049f4a3EfA700bCE8',
      mintLimit: 1,
    },
    signature:
      '0x29a896663327e352db0ee1f919322642a74eb8ffea240591f341a7f9f27ab90336a40c11770eeaaf38f9d01eeade25b4106839571bf2854556018816f104d9401b',
  },
  '0xb5ef0651325312029718f7bd5b0db958b1b011a9': {
    request: {
      account: '0xb5eF0651325312029718F7Bd5B0Db958B1B011a9',
      mintLimit: 1,
    },
    signature:
      '0x3c5e9eacdff921ed3f145cc98109c7be63690598925a95a8c601361e6f1d8a9b665d4b911aff5e11cd7ba8c70ecbab1c2bbd181189f7c04d56cd87495a18226a1b',
  },
  '0x4ab9f9c319a3954c9b34726cb4a3d59ad392b159': {
    request: {
      account: '0x4Ab9f9C319a3954c9B34726cB4A3D59Ad392b159',
      mintLimit: 1,
    },
    signature:
      '0x3e48d7c7b295544902f8e67a36d631e224938c702c526c68f090fea5de251256526bd177998eddba420d9aab1d20f12f2b7d197901a4f1f60f34809be182585e1b',
  },
  '0x5934848a9900da134126e474bb88f5b24e53b086': {
    request: {
      account: '0x5934848A9900dA134126E474bB88F5B24E53B086',
      mintLimit: 1,
    },
    signature:
      '0xd2eba8d6a81ce9dd8a13121dc789e460da84d41f61800a3d8d2894b30fb98c311b4b79ff202a87c3afb1cbf4b4fe438ee44d7c6931035b0bbd28a1a458f1da991b',
  },
  '0xc18c9c9b4e44a0a68739dd9dc1e793ea499df240': {
    request: {
      account: '0xc18C9C9b4E44A0a68739dD9dC1e793ea499dF240',
      mintLimit: 1,
    },
    signature:
      '0x0777a9daf4a1eddd11d40d8fd2b14ca731b1babbfe87921228dcd8c802ac34ab42c92948483ebbcf7c919e9137d336482d5fb31135c5bd6610d3728b251014671c',
  },
  '0x87616fa850c87a78f307878f32d808dad8f4d401': {
    request: {
      account: '0x87616fA850c87a78f307878f32D808dad8f4d401',
      mintLimit: 1,
    },
    signature:
      '0xc4452866fbaac488279139cbdb0ade56aa933a0d9cc55c755b9b897fefd75616533c4e521475d2810a6a7e95de761a7ec1c486f7cedf17baf658f6b74f95111a1b',
  },
  '0x7900487c8f110d00933203e17ca8aaf44033f78e': {
    request: {
      account: '0x7900487C8f110D00933203e17CA8aaf44033f78E',
      mintLimit: 1,
    },
    signature:
      '0xc5abf5d629493cf071dea4cfebfca3b6b4d61e8e3fd32e936ee4459868327be72026fe98d8c08f6c401570b99b9702fb3fe9b1b2f34690aef8930f0cc1a99b3a1c',
  },
  '0xca93c1c564bf5a4e8dcca65ce80fbdf66990927d': {
    request: {
      account: '0xCa93c1c564Bf5a4e8dCcA65CE80fbdf66990927d',
      mintLimit: 1,
    },
    signature:
      '0xeafebac3732c7f3511904e573a89937bdd5b240afadc4b97af34a1c714f8008960a7d9b6738aa6c861b72bc4a0d62510f8f849407a1c38509506aa160d9b54cd1c',
  },
  '0x3dbcc1e9d6c027f6b4ee76e52a66df3e89f738e2': {
    request: {
      account: '0x3DbcC1E9D6C027F6B4EE76E52A66dF3E89f738E2',
      mintLimit: 1,
    },
    signature:
      '0xe2db35eabe8b66a63c08e23e6a26549db1e821b34edbe1f12971ebc71e773d5e5669335b0247f5cbf57573949b38745a2cede8bba7f55fadbe3cb078476a5f031b',
  },
  '0x192621f16c07fdf7abee85dffa7c1b3c2fe4a159': {
    request: {
      account: '0x192621F16c07fDf7abee85DfFa7c1B3c2FE4A159',
      mintLimit: 1,
    },
    signature:
      '0x89ff793e2251a74b623d7d2a0205bc5f8cc4b5b14da08a815babcc6422b3af5f17709b0ccc6e1e658a3fde3a10cc64221c9c0b32fec6e2181205b37e66f386341c',
  },
  '0xd3f527cd3c0f7756a69d88b293544a5dc5cf57de': {
    request: {
      account: '0xd3F527Cd3c0f7756A69D88B293544A5Dc5CF57de',
      mintLimit: 1,
    },
    signature:
      '0xa0affe13c2615ab2a813677c1a589fe6c2e89b4256a4a7a28649d8213d565b5f1b5f169febd07456832598c71715d27c6767d5f04e1adbbf34a2b121fb87c2241b',
  },
  '0x6bfc084ef9ff63cd9535f80ba3b79e7b60e82028': {
    request: {
      account: '0x6bFc084ef9Ff63cd9535F80ba3b79e7B60e82028',
      mintLimit: 1,
    },
    signature:
      '0x80c0f8e3f3e09d8ee79185ecc82c528482f711c432f3f59d9377d5245ded0eac7a4c101f721855b6f2127a1b5baf4d0a7109714e3f6131423d7d201c37b36aa21b',
  },
  '0xdfcf15bb0d3d2e11b1c865fdb1021395c3317087': {
    request: {
      account: '0xdfcf15Bb0d3d2e11B1c865fdb1021395c3317087',
      mintLimit: 1,
    },
    signature:
      '0xe90a2bfc4fd061894bb20a293944726eaeca7594b71f1296a84b997f7cf6021e1d23950e0864cc051959762db4b44616264dfc69ae5e65ac9c8aef2e12bcf0211c',
  },
  '0x6fce63859a859a0f30ed09b12f5010d790618ca4': {
    request: {
      account: '0x6fCe63859a859a0f30eD09B12F5010d790618ca4',
      mintLimit: 1,
    },
    signature:
      '0x3a80b83120e14461acb973f119e68cd17178f1d9f1aa1aef2bdd7768e11f7ece37ca884caaa3690c8d23dfdded5ff323ea000635eea5093e47dc999623bedda61c',
  },
  '0x50e3449024632221562c6bd11e1d5d4a6cc06a3f': {
    request: {
      account: '0x50E3449024632221562C6BD11e1d5D4a6cC06a3f',
      mintLimit: 1,
    },
    signature:
      '0x5fc618bce0c938c68b6311203cec250adb91ed5e67e9ab147e2491cc7e4cc2ba7247c5420227b51df48d3084bc254efc3e025068b2afcd0abf43e73b557adf3a1c',
  },
  '0x3e75497d32942dc8edfceab39dbfbaf2297311c9': {
    request: {
      account: '0x3E75497D32942Dc8eDfceab39DbFBAf2297311C9',
      mintLimit: 1,
    },
    signature:
      '0x17e69ce28242ef11b551ac55553e5163045c56de008e03ff4437ee069637bf9d2a8fc8da7b1acc9b927b8ecd13f30aefd0ae955497e9633b96f0a10bbc1f83a31b',
  },
  '0x2832b244122c71f985d2c58e705741f051ab6ce0': {
    request: {
      account: '0x2832b244122c71F985D2c58e705741F051ab6CE0',
      mintLimit: 1,
    },
    signature:
      '0x1febbbfa9e5b69df5890e6f59d61634de9d43ad59e034b63e3022bf9f4ff237c77d7cbe7e123e60329d8854464637f29865d33b990fab23a03662154504cfa281b',
  },
  '0xe9b31f8daab3d2855cba7327a4a94b2fcbd594c1': {
    request: {
      account: '0xe9b31F8DaaB3D2855CbA7327A4A94b2fCBd594C1',
      mintLimit: 1,
    },
    signature:
      '0xd66a9e6bfb88eeae2abaa6100bc80ef44328a5910a07e82c8ed9fdcbfdc2c0a34970c7a152b9209c8d540266504feebae9ae04039fa8311ddd6033a84151bf8f1c',
  },
  '0xcb95b54862a2e1ffacee1b45f47516cfd65a95ca': {
    request: {
      account: '0xCB95B54862a2E1FFaCeE1b45f47516cFD65A95CA',
      mintLimit: 1,
    },
    signature:
      '0x90f4399238f11bbbec8533cab932bdfc1418ba36f826b5bbe9e36e165f29aa3b5bd84aa7c4171f02f0d83d0efce1f0be308e3b810c60e14d0ae87a4179050dc41b',
  },
  '0x795fe6f884eb7599abfa814aea3cb8d0b3cc47c2': {
    request: {
      account: '0x795fE6F884eB7599abfa814aea3Cb8d0B3CC47c2',
      mintLimit: 1,
    },
    signature:
      '0x6fc24b3041bd9ff00408a163e7a3fe8ed0e113cdb974d1b7e106330df013efd3388df42d3b46add9c321d275229e5482fa58de9f81b5c8651ab3a1abb3c1988f1c',
  },
  '0x249bb6d5a52acaff9954403b7b382e30d4bb2709': {
    request: {
      account: '0x249Bb6d5A52aCAFf9954403B7B382E30d4Bb2709',
      mintLimit: 1,
    },
    signature:
      '0x88284c279022ab1c070391c47dba4a9125b86c33e6560910502fd4b45c2d43a46f6887e012334086305900f1b4e613b30f3d71dd74f812550323ed9b9f326e971c',
  },
  '0x6dbd61c345cad500ba0f8866616dd8eee195b835': {
    request: {
      account: '0x6DbD61C345caD500Ba0F8866616Dd8EeE195b835',
      mintLimit: 1,
    },
    signature:
      '0xb055ab0a05450ec0441cdefec99f77692a29f3517736fe65dad2bcdb73f084040b3bae2e1e54458a2c04d9197e85717c6c19e0193a779cd63e0e21f1894d57021c',
  },
  '0x0f8b8801a738ba366b1703c35057c0fa6bcef5dd': {
    request: {
      account: '0x0F8B8801a738ba366b1703C35057c0FA6bcEf5dd',
      mintLimit: 1,
    },
    signature:
      '0xb822187ce9ae4c8965035f3f57989838390a64b272dd6a38fa2d84764bc099c82e8c426a6aa69b8f654220a13af87da95bb5eda4edff41ea7a44ff57177564c51b',
  },
  '0xd3a9dccb4cfd983ff69e688ddb6491e010301b7a': {
    request: {
      account: '0xd3A9dccb4Cfd983fF69e688dDB6491E010301b7a',
      mintLimit: 1,
    },
    signature:
      '0x34aa0d97631bc2fe15b9ceee63a5e678148a7df03a01b3539946cd780618ea986b35457b4d508d0e6a8fed9c23398d2b391ee87d2d96a84df26427e89f6e55131c',
  },
  '0xf8fbf1d251586fcddd34231a1dd3a9c61c79efa0': {
    request: {
      account: '0xF8fbF1D251586fcdDd34231A1dD3A9c61C79eFA0',
      mintLimit: 1,
    },
    signature:
      '0xf0c333d324a45cd7978c619848eeabc6661e86bd0c98147b0185eea8102f36db0a8834217c2a6d8d15232f4c19f5529c70a1d50fe7f64a05ce4e243c8b3e82f31c',
  },
  '0x95c2ec97903c993efbe4630f1535282a12fead98': {
    request: {
      account: '0x95C2ec97903c993EFBe4630F1535282A12fEAd98',
      mintLimit: 1,
    },
    signature:
      '0x17d003a70314507e0c81b7ad5824ab36936a02121f6e6ceeb3c5baed97203c0a19f032e286288d5affb679d7144128ba18d15ee4ca5862aea7d7abc38b64a80d1c',
  },
  '0x81397dbe05e0df839f16d9c52869f70a69f00bdf': {
    request: {
      account: '0x81397dbe05e0df839F16d9c52869F70a69F00bdF',
      mintLimit: 1,
    },
    signature:
      '0x2e295698966d29ae39adf740070e3b39a58dae7c34a2612ccbdca0e10f9fd6577777e7b72e464eb80016ca3ad7cd1d269f0f201a72943c6e543e3f09162b061a1c',
  },
  '0x7243b40a58b5ed29613b62375d547816ca1352db': {
    request: {
      account: '0x7243B40a58b5eD29613B62375d547816Ca1352db',
      mintLimit: 1,
    },
    signature:
      '0x8b37b0b9adccac28a13654d9f0604cb719f763633302e90b7b73c0f7024f92627ea89f48153714e3daf04312cd0709d67e17ffe4a7d82031705fdb04e96dc8671b',
  },
  '0x8e6ed79c48944265fe5d004010c11cb28aa105b4': {
    request: {
      account: '0x8e6ed79c48944265FE5D004010c11CB28aa105B4',
      mintLimit: 1,
    },
    signature:
      '0xd1bb5cf8d4e4443ed840a4cc9f0008957a5cba876b2e9961dde3b69ec1b96c35581e7cd8867375c3cbf1d7b9d766357046e15da412b00f4f105e4faaf9c056a31c',
  },
  '0xe31deacee1770dfa56f8849724bcb7f8e5f76ef2': {
    request: {
      account: '0xE31DEACeE1770Dfa56f8849724bcB7f8E5f76Ef2',
      mintLimit: 1,
    },
    signature:
      '0xf3c85423de23057a3d8241d8e81e91f752eb31e54c09647fa0fefe37b335fc6f1aa3fa53e7f2470f70121e8224dee7f36106434895fa9b6f24e3ac4a8b40ee911b',
  },
  '0xa1958a37c21372482deff4618baebbec23c9a449': {
    request: {
      account: '0xA1958a37C21372482DEfF4618BAEbbeC23C9a449',
      mintLimit: 1,
    },
    signature:
      '0x222dbf50284c8b536a1efafbf9ea7a7622591a209c090b98fc35060a8954199f15c5c85c3c6e71af7db14bfe4870b400c4a37a4d93419c20df77d97e319cf8e71c',
  },
  '0x8bffa26a7470af9aeb849f0726ffb679ca1d5328': {
    request: {
      account: '0x8bffA26A7470af9aeb849F0726fFB679Ca1d5328',
      mintLimit: 1,
    },
    signature:
      '0xe7ab5e32b3e5e85d91139e337d7b89f56bbe2034252956a78a9a7e5ca087989f55d77a4d2b665481ee5079a4aaa0a805cb566effe769aeb2867f43b07758689f1b',
  },
  '0x6e55a90772b92f17f87be04f9562f3faafd0cc38': {
    request: {
      account: '0x6e55a90772B92f17f87Be04F9562f3faafd0cc38',
      mintLimit: 1,
    },
    signature:
      '0x274bee013d6b9f408022ab28929e40d8bd699fb9f25a0f9126069da76b97b189724b6a038b1cfa68d04a4b70acc6e0797e4fdbda43d7f23d1d86af64dcc73b981c',
  },
  '0xa1025a7d6a0335bebed7c8b98694a07c5effd2ff': {
    request: {
      account: '0xA1025A7D6A0335BeBed7C8B98694A07C5EFFD2fF',
      mintLimit: 1,
    },
    signature:
      '0xab336eeed8baaf6617aae51adc3cb105bb622204ad73b7614fb1d1af5957cbdc1fe1354a622756389ab2b260b0f08852168956cbced86a1e66aac97def3af3d21c',
  },
  '0xfab53b966c50d02f9e1096ea951d47b80b01da74': {
    request: {
      account: '0xFab53b966c50d02F9E1096eA951D47b80b01da74',
      mintLimit: 1,
    },
    signature:
      '0xe05ef1abe50e29d91d5375bdb08bceeb9ad0ac5b8a3bd0372061abe27070d0e62ec02dd0fdbfdd26efd24d19b5dc4737011d0bc6ddcb0d29084358a57a1a7fed1b',
  },
  '0xe75e1ba066acec0428e6b631e1594f98e0d78268': {
    request: {
      account: '0xE75E1BA066AcEC0428e6b631e1594F98e0D78268',
      mintLimit: 1,
    },
    signature:
      '0x4a05082f202b9c0f54efe483b76df20fb7a8106353ffb9d4c9ff4e722723b83c4ba3f35d94ee8a2c164d5155c7a00a865a1390d352798ce3b108b7c2aac154611c',
  },
  '0x41f33a0b874eb3933ef7240014be7613dcd076fe': {
    request: {
      account: '0x41f33a0b874EB3933ef7240014BE7613Dcd076Fe',
      mintLimit: 1,
    },
    signature:
      '0x5f07aad14cf1447ece8a24cef18f5f029e4bc327425efa19a216862d509df5714c2c68ad00cff83a5b67de77df850983d103407fc716e758146c7766607370561c',
  },
  '0x9b370d8eb68a7a36535cb4c2d1996e95f74d4a3a': {
    request: {
      account: '0x9B370d8EB68a7a36535cB4C2D1996E95F74D4A3a',
      mintLimit: 1,
    },
    signature:
      '0x17bbd74953fe3b9914f5497e5339fa4b432f96e3035ac40c589d544b5f5f0395710354d4ea4ada3213b2db560e6db1850bf114a983347992b63fc42de5f104aa1b',
  },
  '0xc2928e12a74509fdab552431220a777027df8e92': {
    request: {
      account: '0xc2928E12A74509FdaB552431220a777027dF8e92',
      mintLimit: 1,
    },
    signature:
      '0xdbafbc92ccc1b8ab513ae717677d2c785b253615c5053ec2ea0bac98facbb0a9372ab35e8b654223e4d2464aae0515d1042ae1ad70cf215a88c4e51e42350d161c',
  },
  '0xbdfa4f4492dd7b7cf211209c4791af8d52bf5c50': {
    request: {
      account: '0xbDfA4f4492dD7b7Cf211209C4791AF8d52BF5c50',
      mintLimit: 1,
    },
    signature:
      '0xfcb6656266fc60e6c47a3cf364a7242bd1fd1a60d3d020461d266cb40e52e5624a01e88889e80dd2dab5e95620e067a5a651d7b102ac804d6d03296aa83a14441b',
  },
  '0x93fc1f35f53a9553534979233caf273214c51fd7': {
    request: {
      account: '0x93FC1F35f53a9553534979233caF273214C51Fd7',
      mintLimit: 1,
    },
    signature:
      '0x636fceb246e5988bfbc0b8dc7e69988f0d166908bf62ca4621626dabcf6037726a04fcaded84824c98602c20eab0c2b4e2fc99c8a0af6dc4aef3b0e35a84f1141c',
  },
  '0x24fa1661924f2c7f37aad8c70433a2d554ae5f9a': {
    request: {
      account: '0x24fa1661924F2c7F37AaD8c70433a2d554aE5F9A',
      mintLimit: 1,
    },
    signature:
      '0x448f663040d8192c4a1009471973c1fcc9fd75897f6a13ceba9dc2e02e10bfed21fc1b33d29d38a3624a4d7eb7cbaee673b73cfeccfb8d3afa7bef4f2a1324501c',
  },
  '0x27f179508c475bc111576b2eb84d4322efc044da': {
    request: {
      account: '0x27F179508c475bC111576B2eb84d4322efc044DA',
      mintLimit: 1,
    },
    signature:
      '0x9df83917761886cf7a26dbd4f98ab2d8ff7487b45f7024f1a098086efa0275cc0f7d9c1724046eb17e9cc340f83b19c4ce678844dee73903fd3a582254c813961c',
  },
  '0xac6c8c77091cb67abc4e9f66b69de4628ab69f93': {
    request: {
      account: '0xAc6c8c77091cb67ABc4E9F66B69DE4628ab69f93',
      mintLimit: 1,
    },
    signature:
      '0xad0bdf76dbe81a36b1f2ba3f9f1a1d839958e5e1fde30f8ab52a030b0a396eea3c049a006b5f219e624cea5651660124d4f1251f91ab58d8f803ad4131e98e251c',
  },
  '0x11d67fa925877813b744abc0917900c2b1d6eb81': {
    request: {
      account: '0x11d67Fa925877813B744aBC0917900c2b1D6Eb81',
      mintLimit: 1,
    },
    signature:
      '0x4b0d536a80e44cd423c13763a2a47a219ff555a5a9644d44a0f613b34755c23306d811f8a9d55584e70b68b5c8acaaf00af4841af5dcde78b8d32cfdb3b4709e1c',
  },
  '0xc1a9bc22cc31ab64a78421befa10c359a1417ce3': {
    request: {
      account: '0xC1A9BC22CC31AB64A78421bEFa10c359A1417ce3',
      mintLimit: 1,
    },
    signature:
      '0xdd93d6ef0956a781538aca30cd02e165fa6957751d1e83d47a3fbe49dfefa2a76e88e730a29b5825a97d927c7bddf0eff7fcbfba6da4f675c55d85d7ed102e741b',
  },
  '0x36e946cbc397a6c450f8a222ae5f14127b3ac5d2': {
    request: {
      account: '0x36E946cBC397a6c450f8A222aE5f14127B3AC5D2',
      mintLimit: 1,
    },
    signature:
      '0x4e57a20792e1eb49fe01fa7790660d2342120e07989b805f3779334726840e2508dcf87d79b2cdf996304b6cf2d91a26135c02da3bbea24a9256455ac9d807f91c',
  },
  '0x66f1c92b29441bcba925c07abbba2e23676b79a4': {
    request: {
      account: '0x66F1C92B29441BCbA925c07abbBa2e23676b79a4',
      mintLimit: 1,
    },
    signature:
      '0xc0a82b4b940434feaeea9c179ca03b141ff33d30405741c1e2281c9dee46f754069b5383109a98aff68a5642d62917b7036be829a0ca7f2256f74333471a2d881c',
  },
  '0x4855ea3738b30192e86d52ca9a3ce4030c3de794': {
    request: {
      account: '0x4855EA3738b30192e86D52cA9a3Ce4030C3DE794',
      mintLimit: 1,
    },
    signature:
      '0x062bf2a0b5ec6e0dd9c721330f6c67e7145bb191c629787435d76525dc4402600f45cbff027f89c10ab03b8c1cec928830b24a29cce3a950f0d3c7474024a3d01b',
  },
  '0xbaeb92889696217a3a6be2175e5a95dc4cffc9f7': {
    request: {
      account: '0xBaEb92889696217A3A6be2175E5a95dC4cFFC9f7',
      mintLimit: 1,
    },
    signature:
      '0xdb878405b04fba933d701e99898b63ab888a5fa072b417ed81096aeafc214a626bf2a910ccd27d9988ba24baf26a16778f8f3fac8906365bf031ef1ff7a537621b',
  },
  '0xdd1b16393076506fd4c8b7c2cc264fa0c25f44f7': {
    request: {
      account: '0xdd1B16393076506FD4c8B7c2cC264fa0c25F44F7',
      mintLimit: 1,
    },
    signature:
      '0x0642508800f5c19445b87df20a73e70b5e8d4a85bdff3c4d2b8269e266d9a4790406ba6254f7a8387ef45b5232b8687d5d38f0692e477e1a701c63b32c40046f1c',
  },
  '0x49b195ffd5b655b2f10e6687111d4a3283f224ef': {
    request: {
      account: '0x49B195fFD5b655b2F10E6687111d4A3283F224Ef',
      mintLimit: 1,
    },
    signature:
      '0xeba5df766aa33d101ade48602cbe4c51e0898a00edb29e1566f2718153f9b34f71f709f21cebedbe818a72cd465f4a02c6e59d6c9e9e67856c3056a998e1b7331c',
  },
  '0x5ccb3d68f31bab06e4d1a0e779d24149d1684941': {
    request: {
      account: '0x5cCB3d68F31BAb06e4d1a0e779d24149D1684941',
      mintLimit: 1,
    },
    signature:
      '0x4c23e624fc512f895266512bb9f199c77ee6e4725bff5ab4bf3fb84be0b14bd91432e8204ce97c1894379fe6662935a43e9a18bf48b1062a49fb2e499c14ecb21b',
  },
  '0x6f6b9a91a78c2e23ea086e45a53111ffa61195ad': {
    request: {
      account: '0x6f6B9A91a78C2e23eA086e45a53111FfA61195aD',
      mintLimit: 1,
    },
    signature:
      '0x1594ff05afe837a6be76eb8812b668c635af6b870b7f4c690070d5582c4130e76b9819078a6dc2d068eeaa13653d48e5b432c9eda61c31eb202fc477df06340d1c',
  },
  '0xe9d707b807d39b5592b95a57231e12255838e31e': {
    request: {
      account: '0xe9d707b807D39b5592b95A57231E12255838E31E',
      mintLimit: 1,
    },
    signature:
      '0xd456be6bab6b7c379346ce079bcebbf301a3d3e60291190f9ba90a90f81b4ad35cacb30fa112ba2ce18df9415b8692bf945058e885aaa5b08fc1c4539c75a0341c',
  },
  '0x872b6c97bd7b73e7c700442a2a5415435c2317e7': {
    request: {
      account: '0x872b6C97Bd7b73E7c700442A2a5415435c2317e7',
      mintLimit: 1,
    },
    signature:
      '0xc68552cdd22c4a1aa313800a70e773838e2374dbad76073c17906c072a39d3ab7ff50f6d99ef1c4e4c2824781c6318464b3e088203d05d255f715b95a0c69b261b',
  },
  '0x67d69ca5b47f7d45d9a7bb093479fca732023dfa': {
    request: {
      account: '0x67D69CA5B47F7d45D9A7BB093479fcA732023dfa',
      mintLimit: 1,
    },
    signature:
      '0x6527d0f1c9fd8d62ae360f0381291fa542fa5809f625c8c076cd1dd00631e819716f5cac2871648336e3c92f22677552461a2be2a6c8657e258325fda1971efe1b',
  },
  '0x05822d7fccb8e2d6291db3701a1c22a5df262aee': {
    request: {
      account: '0x05822D7fcCB8e2d6291Db3701A1C22a5df262Aee',
      mintLimit: 1,
    },
    signature:
      '0xe0fb1030c0463836272001f009b1fabddcc788515b50b7af09f1424e2e14b81025f9f5eebf68385db914a367c309298cd0ceb6435ab1ef6b96488fb612544b9d1c',
  },
  '0xfe13c6d06121a27cae69e634ac25e8d3316d3048': {
    request: {
      account: '0xfE13C6d06121A27caE69e634AC25e8D3316D3048',
      mintLimit: 1,
    },
    signature:
      '0x4a75da635672e2a2adf1bd16b0260281eaaf9c432ea572d39b80c88a9c4de3a1071cf52b9ce376cb5f3f854643b5fa1a4e47e4efdfb12536dfabe1be731821e41c',
  },
  '0xa3a772d1e714be8c52d0b8929952728a93666ccd': {
    request: {
      account: '0xA3a772d1e714Be8c52D0b8929952728a93666Ccd',
      mintLimit: 1,
    },
    signature:
      '0x4d3dd26a9fd4865c71ad54172b4cd1150a4e31fc6f4c324257992a5b60d492af00881cb366f1f165c6c4fbe5825e0a381d17111ed8061b4707f43c75278b7d201b',
  },
  '0x16e74b3b60406949a0e98407300a1e6eee23acac': {
    request: {
      account: '0x16e74b3B60406949a0E98407300a1E6EeE23AcAc',
      mintLimit: 1,
    },
    signature:
      '0xe865ab35c9055ba29068421a64804d09549cb7b5c9d7cab499bede658cd2730922aba534d52a21c2bf9062475096a3a1704569d8d30216ef4b20d6a220a57a771b',
  },
  '0xc39f52b4075c82bd69742909505a352af54f5e5b': {
    request: {
      account: '0xc39f52b4075c82BD69742909505a352aF54F5E5B',
      mintLimit: 1,
    },
    signature:
      '0x46622a72db34405600c5dd65efe73168ee1d91e3baeace86b9781dc7f96fdabf0896f9e44623789edd8a324473df3eab4b1e4ee31696b533ecb9d5fcb1f0bcac1c',
  },
  '0x994649b6f14e340c2878885d2e9d90bfd4ee8aae': {
    request: {
      account: '0x994649b6F14e340c2878885D2E9D90bFd4EE8aaE',
      mintLimit: 1,
    },
    signature:
      '0x150c0d68b3846c5f265f28ac1278aab73c7e5727bae267c6d2c50f625bdb67b5212356cb5aaf0ca7fa3de2efea5a3b5f4f9d42ef9e5f7ec1a696a0279c2cfbe61b',
  },
  '0xbf5c2e26259e50f22b0857db76a6ecb9ae7bfada': {
    request: {
      account: '0xbf5c2E26259e50f22b0857Db76A6ecb9AE7BFaDa',
      mintLimit: 1,
    },
    signature:
      '0x5ce930c6c6fbb8626599f7f5874e3998e737cb65d7aed31c59b39337a13c8a6831e46fbe933c47bec23a1f11dcce3d5b7ee533dbd8245e54ca425ee58641913b1b',
  },
  '0x35080cda95a7a6959effc293f4a4274110b77777': {
    request: {
      account: '0x35080cDA95A7A6959EFfc293f4A4274110B77777',
      mintLimit: 1,
    },
    signature:
      '0xd6771190e6e65159dc50f4a1a4830ef64425dfaa6d144081f4f30205e1a3bece3c108218ed1c6a26714a4b15b53d8fa7ea8539aff754aafe6f67bd612a23fed31c',
  },
  '0x38f046ae944021351ce5c2486dee3027c949858f': {
    request: {
      account: '0x38f046Ae944021351CE5C2486dee3027c949858F',
      mintLimit: 1,
    },
    signature:
      '0x5db4b9fcd50f33fa2f75e858711227d4033bcd6fd42fc9a12a9701f248434fdf66ed7f715106f95d62b2d1c08f4976e3ea1ad5b4ab7fc751a76245bd6dcb1aea1b',
  },
  '0x11a2481e5a24e226763155ddad8112f3125b68cb': {
    request: {
      account: '0x11A2481E5a24e226763155DDAd8112f3125B68Cb',
      mintLimit: 1,
    },
    signature:
      '0xf33142a1bf992efbe2f669540eb68f4fc5ac34683c81e3d39fc8fb45d94ee8ac374bc3de8abd96176a6d13968eeb90aac6b6bbebdb0595838f02cceb96f04e101c',
  },
  '0x1395f53b0472827c78964316a7f5595f302e10c9': {
    request: {
      account: '0x1395f53B0472827c78964316a7f5595f302e10c9',
      mintLimit: 1,
    },
    signature:
      '0x0abac740db8a231300c11b75a9346efe8b8a097850f9598b82a1cff3b5feebfc6bbcd95c78629d4febc4b751b112cb6c481251b06c77e9c2ce5ef6d285f2403a1c',
  },
  '0xd5b12178161244cd1ac9ee4857b53f0b6e7c5a46': {
    request: {
      account: '0xd5B12178161244cD1ac9EE4857B53f0B6e7c5a46',
      mintLimit: 1,
    },
    signature:
      '0xd60791cde7a2adff51bc59d69d0283c229e4ec27ceed594f5ee1bc7ac0df70fa7157d66d4e13c566021efad6ed88039a5b54ea2539415e1f0f070b4ede5ef4221c',
  },
  '0x6d111859c6114c15d3541d53871b9e0a43c07475': {
    request: {
      account: '0x6D111859c6114C15D3541D53871b9E0a43C07475',
      mintLimit: 1,
    },
    signature:
      '0x83ad1dc4b7247563f1a31e4d7beb8dd673aea4306bddf9ab855d20379949a92541713cd5a5ff7b2645dad3bc6cc49d3ac6d9960c87cb05ea07cf4418a8b7bf161c',
  },
  '0x27e19548ca1d19c8c68fb0e6d2718fb56f71a6bc': {
    request: {
      account: '0x27E19548cA1D19C8C68fb0E6d2718Fb56f71A6BC',
      mintLimit: 1,
    },
    signature:
      '0xec05f8e8721289d671d3534851d8f9fb0d4656f1a2c0b9fa627250fda5295e8c0d6b8258950cc7b221318da162e01e96726f545b4f92a7ca39ad670bc03156b11c',
  },
  '0xdbedb1b7d359b0776e139d385c78a5ac9b27c0f9': {
    request: {
      account: '0xdbeDB1B7d359b0776E139D385c78a5ac9B27C0f9',
      mintLimit: 1,
    },
    signature:
      '0x868cf69473bf0c71b79315357012e34be8d536bd812c1af3fedf3cfee01ca5ad42b08fb97d0b23d9c61f8b1211f5eb3a32beb034d1254a47381b2a3bbd97531f1c',
  },
  '0x46e3afb8e79d35bc83ad41a84443dd8e26bb5d33': {
    request: {
      account: '0x46E3aFb8e79D35BC83ad41A84443dd8E26BB5d33',
      mintLimit: 1,
    },
    signature:
      '0xff2cd6e3ed73c580d49a28c9719c3f98768fe8cdf02359c750db98048923f6ac3317cdb4c5e6827df4ce52b12a428ffe57f0383e5c02c7526f93e41159568afd1b',
  },
  '0xd88f6bad056f9fdbadaed75379afa5e422e05314': {
    request: {
      account: '0xD88f6BAD056F9fDbADAed75379afA5E422e05314',
      mintLimit: 1,
    },
    signature:
      '0xb0fae9e45648e4963d104747610b550680e241c4a472d5560fcd394657743f3869bc0c860144344c64b91cb08d05dc560ee0a059f6efc13768c5e58c10cebb151c',
  },
  '0x68512d66e6386369686f58a912c86b390b9299d0': {
    request: {
      account: '0x68512d66e6386369686f58a912c86b390b9299d0',
      mintLimit: 1,
    },
    signature:
      '0x9d28fd5805eedb8430a1f361c00660772aa9c5f3f31baff4124ff36b41b519735db85156390504eb300ad3a03862c7cf364e8a8ba7ba59ee0fbb0da20fca77df1b',
  },
  '0x89cd4c4be4e5bff559517e25ac05d6c9ad01d89c': {
    request: {
      account: '0x89Cd4C4be4e5bFf559517e25aC05D6C9AD01d89c',
      mintLimit: 1,
    },
    signature:
      '0xca235935dbb54286b23defd7d9524ee224825ed89640f8c5165ef94f0164cf604e7ae9fee47adad29939ec7184e0df42be03e79670a68b31a52a87807935bcf91b',
  },
  '0xdd48cd59064d34959cd2de66d02b531ae1db1225': {
    request: {
      account: '0xDD48cD59064d34959CD2De66d02B531Ae1db1225',
      mintLimit: 1,
    },
    signature:
      '0x47dbe34057710b49dfb93ae3882472151aa7fe62f679a2b15a18022ddc225abb489a9f585faf42a86e9f37f3c3eb96ad3829ee417d566117ce808959a9272ca81b',
  },
  '0x794c0922a6d97bd042973e2f0715dada8381b810': {
    request: {
      account: '0x794C0922A6d97BD042973E2F0715DaDa8381b810',
      mintLimit: 1,
    },
    signature:
      '0xff94246dd8f7dc2b0acd2b5c834ede86fb5c36f3b7c0a9296d786f55d42da5cf623c7517972cdca9f15f903c9081066d412ab8896504f2012e166af5489f395f1b',
  },
  '0x3df71a587a968a4d070f905eb2805e9d36c273e5': {
    request: {
      account: '0x3df71a587a968A4d070f905eb2805E9d36c273e5',
      mintLimit: 1,
    },
    signature:
      '0xcdf766b172453d0875932164cb982b241c5396c82a47707a1ebe45199ada4e7b71b41520b20da16b497226b45f499ad0724d87a28ee87faee6f489df00c54b4d1b',
  },
  '0x34e2cb7513a50b19f450a067ed5230a86c13a2e9': {
    request: {
      account: '0x34E2cB7513A50b19f450a067ed5230A86c13A2E9',
      mintLimit: 1,
    },
    signature:
      '0x42bd0e4373958f4eeeced32c35d58d17442637fa958cb950866a4277e6deb2580fdbe4b489f948b08946ded924a92b0842db6226480f1700eb448075474143421c',
  },
  '0x5a98dbceeca053b7845fc4dd79124f66908ade50': {
    request: {
      account: '0x5A98dBcEeCa053b7845Fc4dD79124F66908AdE50',
      mintLimit: 1,
    },
    signature:
      '0xab0cbbf6d3077e21263e45a6a1cf132faec5ff45547bca741e545ef72c337f922220bd73aae0556883427afeb034b991d65b704c8a2c7299e7f98aa7abaf51f51b',
  },
  '0xe2f1f004ca64e8ea41be8e5b13747da2c310566f': {
    request: {
      account: '0xe2f1f004Ca64e8Ea41bE8e5b13747dA2c310566F',
      mintLimit: 1,
    },
    signature:
      '0xb52eb31ea6d13e30faa26dad8491a6b587ce4f571e94200d5498fdece42e8030095437982e7ba3df4c69cc34926c882171c016e6fa028dcfdbd6b265eac8ea451b',
  },
  '0xaab0cb12db4f95a4cc754000857612f7dfb331b4': {
    request: {
      account: '0xAaB0Cb12db4F95A4cc754000857612f7dFB331b4',
      mintLimit: 1,
    },
    signature:
      '0xd2731462789a35029612a59bed352a038afbb68ca42a488c8f693fa33fc47dbb352c2cfa8e5c3de4cf691603a6842f1e18d3ff24cde0fb12f7d1978d403cfcde1b',
  },
  '0xf627e5f4bad95a956468d8bb6ee20b119f992e96': {
    request: {
      account: '0xF627E5F4BAd95a956468d8BB6Ee20b119F992E96',
      mintLimit: 1,
    },
    signature:
      '0xd06959b468c2087bd0bbc8c050cc5946d7331a9c42eefe21dcbd9e71c64772997e481119adf97ea3c0bdda070ee5b0a441567cbb03ef412d482fc2393a7d73451b',
  },
  '0x469adaf766fb35f1a3c2569fe8c57a50f4b39131': {
    request: {
      account: '0x469Adaf766fb35F1a3c2569FE8C57a50F4B39131',
      mintLimit: 1,
    },
    signature:
      '0xd143b0a8c71ef07e2413e7eba5a7d83cd33f2ccbd9d5b20e4fd747e255616ac528f1b057f0c0528fe09a88ea55e50ebac740fa8cec48ea26df8459675e1a09761b',
  },
  '0x1e601320e64e0edde73e136911fffd04767bddd8': {
    request: {
      account: '0x1E601320E64e0EdDE73e136911fffD04767bddD8',
      mintLimit: 1,
    },
    signature:
      '0x10e085191e7ba06b619f8dda378c09e7b88543be7dcf21c5878699965d262a8870e0f2ee400df794f573a21d9ae99daaf85006679c1380cb00fd162b4824bec11b',
  },
  '0x17ec8d5f9e2821b7b15297382eb395d9cdb3c3be': {
    request: {
      account: '0x17ec8d5F9e2821B7B15297382EB395D9cDb3c3be',
      mintLimit: 1,
    },
    signature:
      '0xddeb0873d60e822e8ef2784629340d543690239915495e525ca40ae2a58c2e494cb8f0575f5deea2cb9060cec1eb9acb7031bffdc64e26a862f1d8bb436a32ae1c',
  },
  '0x40a9b98a310c4af1bb496130ab889875fcfe7920': {
    request: {
      account: '0x40a9B98a310c4af1bB496130ab889875FCfE7920',
      mintLimit: 1,
    },
    signature:
      '0x4254e54e6a31b32aeb59c87f1f2f4707fa6c9b2b71afa2c1655138e75a6961ad70f2b59d46d03cac84913630e202823f39e6d644d37eab21fb578a1f9dac66c51b',
  },
  '0x1b805e28d1536f28cd3c8efb51b56248522ed11d': {
    request: {
      account: '0x1B805e28d1536f28cD3C8EFb51B56248522ED11d',
      mintLimit: 1,
    },
    signature:
      '0x8e8ca7b1a5d0527dcd97dc308e414b174838b68e11c1bc61206f3d0f6ecaf74d5996bb7f18fefc57ac4c46922b002958798db0292fb7eed8eac5b72d9e7d03411c',
  },
  '0x389b62356ed6a23d251bb47afb23009c1cb5fea0': {
    request: {
      account: '0x389b62356Ed6a23d251bb47AFb23009C1Cb5feA0',
      mintLimit: 1,
    },
    signature:
      '0xcd2576101d050a05bb25ce567ce1f18ef2844a4a47a37dda96ca690ae438ac59595abbac735f4c6d24cb6d19ac1fc2689c999ab416e22e86a2122999aa8d46381b',
  },
  '0x20151c34d01d6785493f3416b3f82812a3dbb46f': {
    request: {
      account: '0x20151c34D01D6785493F3416b3f82812a3dbB46F',
      mintLimit: 1,
    },
    signature:
      '0x7f88a13fb1db7c105c97375d7ef3277e8a903c8378f9d625c3dae53f2d172d835aa95fc4a6d5ccdc58a0cba4615cf1785367e7cfdd6bc60c2f91bff0fdb8e1d61c',
  },
  '0x4350f6ca9e79ea1dac9cc7d4ff3b60beda1cf308': {
    request: {
      account: '0x4350f6ca9e79Ea1dAC9CC7D4fF3B60BeDA1Cf308',
      mintLimit: 1,
    },
    signature:
      '0x2431389443e55cf2d44418d6ac8a1483030b380ff783091ea94ee64d9b4419ae42700d7f82be5cf713ecdfdfd3ca60e6e8801a2d86c6eec2e10750754d9f4e7e1c',
  },
  '0xfa4baf8d47de2f86718961ea22b9135a9cfbc162': {
    request: {
      account: '0xfA4BAF8D47DE2F86718961ea22b9135A9CFbC162',
      mintLimit: 1,
    },
    signature:
      '0x40a0f4919c026bda784d8a0ef55fc2944763e2f3c5e87b46239f466c239d62ba419b72144d16be1028bd99cbe7b145d07b7e3aee52ab3c71c099a33dacb1c1021c',
  },
  '0x06e6da246a3a562e7008e695f7bab3a5d3b61b4b': {
    request: {
      account: '0x06e6Da246a3a562e7008E695F7bab3A5d3B61B4b',
      mintLimit: 1,
    },
    signature:
      '0x6bf69b7d501a333b8b2d642917ec37c08c5d5558a8a94bfacd7fc6d69dfc834b142fa2ce9e1b1c57f85943cfaa72ecdeb1eb2e275f08e7a7bf9d50609fc3a7c61b',
  },
  '0xad399b9f20f2e0316e27d6481287a7d4ab65f509': {
    request: {
      account: '0xAd399b9f20f2E0316e27D6481287a7d4AB65F509',
      mintLimit: 1,
    },
    signature:
      '0x72c905f066e418ee31a7809c5d04d000f1622b396da2972f4f810d119191323539830b7ceb054d68a3e2c278afe502ee3eb1a435d825e8039c91f55ab31e399d1c',
  },
  '0xef2f8a260904051c33f88832c5fcf4d68e4f806e': {
    request: {
      account: '0xeF2f8A260904051C33f88832c5fCF4D68e4F806e',
      mintLimit: 1,
    },
    signature:
      '0x50f16fe5f2b8c8a235d8f79e590a60a168b2c4de67eaede262574f147957bd4627cb35b1690fe81592efb9dd9f0ffc324a16ac70fb5ca03a44cef8216e137fc81b',
  },
  '0xb5686a0c1cb9a808dc94ea8e2b2142193d259295': {
    request: {
      account: '0xB5686a0C1cB9a808Dc94ea8E2B2142193D259295',
      mintLimit: 1,
    },
    signature:
      '0x8687e0d57e772aff2d46cf433930736a2eead1ebb73fdb2c56912a84cda7a4e757751d583d0b0e76161d1d7738113613fc56612de6acaa8c94865a01e4ba45541b',
  },
  '0x7fb95b4292c9a597508415323aa29791f7d23d58': {
    request: {
      account: '0x7FB95B4292C9A597508415323Aa29791F7d23D58',
      mintLimit: 1,
    },
    signature:
      '0x6df8a0c5c5f1d3ff1e8e13ccc64ab5ec663a1b6c1f34fbc5fd60dcba9f5fb69f2bf34fd1f9e9ee9d202e9aaa792d398c34c40a19c76976120d76339671f58be61c',
  },
  '0x7bb5cc37a81623d6556d64c5d89333d3aee2a39b': {
    request: {
      account: '0x7BB5Cc37a81623D6556D64c5D89333d3AeE2a39B',
      mintLimit: 1,
    },
    signature:
      '0xcba36e62e83529d7795d867e606ec0b50968ee0c61260b483fbdf65103ddecbe2ac98b104e918bd8a5689b257f02b5fdee201ccaa9d22e2f86a3ee7b59d0a7f41c',
  },
  '0x419b9d80f7b3a296f3b9dc62210bffcff0b93320': {
    request: {
      account: '0x419b9D80f7b3A296f3B9dc62210bFfcff0B93320',
      mintLimit: 1,
    },
    signature:
      '0x64dd5ae168fb76744a4b2c691c398855ec17a19880d806a13d9151da8906453272e2712b293b1bfc6fc1d68c470971a850a1c71d7e5d1fb7215b27f894b3eded1b',
  },
  '0x2f9cc4f5476b7072bf5e6d84e781e514bf8c60f0': {
    request: {
      account: '0x2F9cc4F5476b7072bF5E6D84E781e514bF8C60f0',
      mintLimit: 1,
    },
    signature:
      '0x99c53084dc44b358d6ce81c4354f989e144ecb76472bd55c4d5b0acd92511b870d6c39918312b378d47cb56551f6b6c3a8aadc30b18b5bcadbc3d17e23bec1d41c',
  },
  '0x35a0179e9606d567a5c2b595f1816512e439e0e0': {
    request: {
      account: '0x35A0179e9606d567a5C2B595f1816512e439e0e0',
      mintLimit: 1,
    },
    signature:
      '0xd34fa40db66b4648a5bbd92d9c11f64b117707bd9f9d0e3195ae1a6f4765eb664d352e3bc9fa11a2ddb9894b9af69451ca74e1a2f4fabc6a4434c29e007984881c',
  },
  '0xf4304232324aea84f73362f3e8adc06c39d8fecf': {
    request: {
      account: '0xf4304232324AeA84F73362f3e8ADC06C39D8FecF',
      mintLimit: 1,
    },
    signature:
      '0xc9b08ab5ee1b0ea8c80eeb10346a5924689fd688828a7a555d94c8ea4e17231a3289f609dc3d8b10518a59ff80ad01a7218134e922f0f74ceb6fd85ba1c940ab1c',
  },
  '0xbc6d2a84602ed2b77ae8c01e610d138e5f597d18': {
    request: {
      account: '0xbc6D2a84602eD2b77ae8c01e610d138e5F597d18',
      mintLimit: 1,
    },
    signature:
      '0xb2c6c427b8d5fdb0ae71916618030297d05990c461e4626e06ee0e984886755b67de6df08da7b85e40741f3ab35765cbb7d67758e7e00328610bd0bdfb8f7ef21b',
  },
  '0x99f135f5c36c1a21065353e81a3a8b7239341d0b': {
    request: {
      account: '0x99F135f5C36c1a21065353e81a3a8b7239341D0B',
      mintLimit: 1,
    },
    signature:
      '0x0ce4572a348d7a5d0856c8ed1f9a2bf86abb91642a60275c79f201af0762b4490a0d5766025a61c309024008e698825f70acceff157d15dce4535b8a2e26213a1b',
  },
  '0xeb3445c97a02c7229efe8ad44784b1d644f14a1b': {
    request: {
      account: '0xEb3445c97A02C7229EFE8AD44784b1D644f14A1b',
      mintLimit: 1,
    },
    signature:
      '0x1800a3537d8e421e42ed8eeb390584d77b396e5f7955a72ad9d12dfa772337455951872f10283f9cc259d0aed547fcd64cc589c345708109dfb26f987baa21611c',
  },
  '0x085144a1554fa0caf9bca48b4196a19209713725': {
    request: {
      account: '0x085144A1554FA0CAF9Bca48B4196A19209713725',
      mintLimit: 1,
    },
    signature:
      '0x41af68c9b1a0f92a0df7d3972880924d521216eb181ea980d5a4c211c9d309f15bae78f7224c16386153ac3b7329a2ce64bdbf03c2432c93584948bdf81b9f461b',
  },
  '0xf89f6940406be238b39ffd6805880a5cf15a8ae8': {
    request: {
      account: '0xF89F6940406Be238b39FFd6805880A5cf15a8Ae8',
      mintLimit: 1,
    },
    signature:
      '0x5e05c2d83d19e8b16b9f7306b7c57f0796fcbdd6f8175bed6da5a4c3c30dbd1c25af010ac136dd9ac664add99f79346aacc6dbf00d2fb4545035b70eaf4019c91c',
  },
  '0x622e95b81334856610a17029a7107d91d1f77781': {
    request: {
      account: '0x622E95b81334856610a17029A7107d91D1F77781',
      mintLimit: 1,
    },
    signature:
      '0xb801fa4013e8bf3f682cd63bc8d1e497aa1e10f0350e2f333881b7bbbf807ecf6f5fa4b51f23440993355ce188c8dd39e159fee3b1c3809c96cf27cb8e56801f1c',
  },
  '0x7924d4307cd7d1a502a83409046dcdc8f2147234': {
    request: {
      account: '0x7924D4307cD7D1A502A83409046dCDc8f2147234',
      mintLimit: 1,
    },
    signature:
      '0xbf7d9520bfada636bd326a98163ddec3a833a747842f968b6231a81944f8dff710dc103da595c315d951c8bffb24a250f47e6462e5975ff50d334d23283c02ae1c',
  },
  '0x1e6ecb95b8979fdfa1aaaa118c841f84b783c581': {
    request: {
      account: '0x1e6ECB95B8979fDfA1aaAa118C841F84B783c581',
      mintLimit: 1,
    },
    signature:
      '0xe72b30f0cd359195c9e8f4922ed6faee6278063653416f8c23d473af0a2a481b7ed34285d5d87a934911dec83978977c47dbae54d1365ec26ee2c224425334861b',
  },
  '0xcd5cf97b3b916fb71e6e25f446828d95e7c0ca01': {
    request: {
      account: '0xCd5Cf97b3b916fB71E6e25f446828d95E7c0CA01',
      mintLimit: 1,
    },
    signature:
      '0x09fe2c0565f52f9f4b294556fd8499037034e8d0dba6a68782b4b83c31ec1a91577fa0459a9e3c78d69b53b84315f1154160ca3d9db97e82641304fb2c59d8971c',
  },
  '0xdb354d8354484c7a9f62eacc7d5207fe6c56ab92': {
    request: {
      account: '0xDB354D8354484C7A9F62eAcC7D5207FE6C56aB92',
      mintLimit: 1,
    },
    signature:
      '0x089b5b4f808755055f17c1a5cb90ed32fa982492d8a9df39f8261f83bafdad5610ddaca498c13ec9ab224eda8f6220a0179ff1ee26507dc0e4d130638a6a685e1b',
  },
  '0x8e7d78f1e4cf26a42949cf99601189eaf6337704': {
    request: {
      account: '0x8e7D78F1e4Cf26A42949Cf99601189EAF6337704',
      mintLimit: 1,
    },
    signature:
      '0x32e34aa0ce59073e29e4a1180d7b4607df183f8a6cd062e4459485f918af1ac7176224784e26b70969940b7c315e1ac8ff0b78554a0f022f6bd6caf3f0902a7b1b',
  },
  '0x651c225bd506fd80e6a7c0d02e53d873c7e2de9b': {
    request: {
      account: '0x651c225bd506Fd80e6a7C0d02e53d873C7e2de9B',
      mintLimit: 1,
    },
    signature:
      '0x6f839f1966a33614b4f85cd01a682a6024643e6341a5ad2f687f085539385f8b5f75d43358b2c81d12c77ed61ee84a682ed00b0be8ab4dbb119215bc8273beb91c',
  },
  '0x3b29d2715788a75feb1e36a9175f2d8889e9e0fa': {
    request: {
      account: '0x3B29D2715788A75fEb1E36a9175F2D8889E9e0fa',
      mintLimit: 1,
    },
    signature:
      '0xe011a41526dccaef6df7a5cb99a9009955034e71bc845393bc1269a39f376e8642b3b0c04915ae5ee80b63d75aaccc32ceac9728b8a4e3d339899c5f4036b4db1c',
  },
  '0xb240db4d3b0570300b7fc6f44abfcddc56b9cebc': {
    request: {
      account: '0xb240DB4d3b0570300b7Fc6F44ABFcDdc56b9CEbC',
      mintLimit: 1,
    },
    signature:
      '0xdce755b62b56d921d35c7fd0ec7acb5552a68f47347c274a0ddf027f398a649565ad3f2d688735226b1948897f650859e8a12af4c43fa5b9801f6293709e1b151b',
  },
  '0xf22935d26e13a2940e0ba5f539d17ba69b53e0ad': {
    request: {
      account: '0xF22935D26E13A2940E0BA5F539D17BA69b53E0aD',
      mintLimit: 1,
    },
    signature:
      '0x5280071a36f40e35f0e9c3e13068c555eb589be3612fa406e02da09994ade08d4e142aaeaff8cc63923ea90db33eb2e878181249c0b4eccb4a93a2c35ef5c13b1b',
  },
  '0xeb501d38ddab9ee151e20ac1a0b4109a3fc7e208': {
    request: {
      account: '0xEb501d38ddAB9Ee151e20aC1A0B4109a3fc7E208',
      mintLimit: 1,
    },
    signature:
      '0xf424657975e8a1c176d0ed5ae9a485c94167da7361f64cafaafa9e3b88a6a0806c0268511a084b96ca1038e3b32d70ec9d69ea94e3caf67c34a22c581fccc40d1b',
  },
};
