import { useNavigate } from 'react-router-dom';

export function useRouter() {
  const navigate = useNavigate();

  function goToMint() {
    navigate('/');
  }

  function goToExternalLink(url: string) {
    return window.open(url, '_blank');
  }

  return {
    goToMint,
    goToExternalLink,
  };
}
