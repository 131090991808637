import { atom, useAtom } from 'jotai';
import { useWhitelistFacade } from '../../whitelist/hooks/use-whitelist-facade';
import { whitelistMint, publicMint } from '../services/token-contract-service';
import { useAccount, useReadContract } from 'wagmi';
import { useCurrentDate } from './use-current-date';
import {
  extractError,
  MINT_START_TIMESTAMP,
  TOKEN_ABI,
  TOKEN_CONTRACT_ADDRESS,
  TransactionStatus,
  WHITELIST_PAYLOADS,
} from '@chad-mint/shared';
import { useDialog } from '../../ui/hooks/use-dialog';
import { usePrice } from './use-price';

const loadingMintAtom = atom(false);
const mintErrorAtom = atom<string>('');
const mintStatusAtom = atom<TransactionStatus | null>(null);

export function useMintFacade() {
  const { address } = useAccount();
  const price = usePrice();
  const [loadingMint, setLoadingMint] = useAtom(loadingMintAtom);
  const [mintError, setMintError] = useAtom(mintErrorAtom);
  const [mintStatus, setMintStatus] = useAtom(mintStatusAtom);
  const mintDate = new Date(MINT_START_TIMESTAMP * 1000);
  const { whitelisted } = useWhitelistFacade();
  const {
    openDialog: openMintModal,
    closeDialog: closeMintModal,
    isDialogOpen: isOpenMint,
  } = useDialog();
  const { data: currentStage } = useReadContract({
    address: TOKEN_CONTRACT_ADDRESS,
    abi: TOKEN_ABI,
    functionName: 'currentStage',
    args: [],
    query: {
      refetchInterval: 1000,
    }
  });
  const canMintWhitelist = whitelisted && currentStage === 1;
  const canMintPublic = currentStage === 2;

  async function executeTokenPublicMint() {
    if (!address) {
      return;
    }

    setLoadingMint(true);
    openMintModal();
    setMintStatus(TransactionStatus.InProgress);

    try {
      await publicMint(
        BigInt(1),
        price
      );
      setMintStatus(TransactionStatus.Success);
    } catch (e) {
      let error = extractError(e);
      if (error.includes('Invalid account')) {
        error = 'You are trying to mint with an invalid wallet.';
      }

      if (error.includes('Exceeds limit')) {
        error = 'You have already minted, please check your wallet.';
      }

      if (error.includes('Unauthorized mint')) {
        error = 'Unauthorized mint.';
      }

      setMintError(error);
      setMintStatus(TransactionStatus.Error);
    } finally {
      setLoadingMint(false);
    }
  }

  async function executeTokenWhitelistMint() {
    if (!address) {
      return;
    }

    const whitelistPayload = WHITELIST_PAYLOADS[address.toLowerCase()];

    if (!whitelistPayload) {
      return;
    }

    setLoadingMint(true);
    openMintModal();
    setMintStatus(TransactionStatus.InProgress);

    try {
      await whitelistMint(
        BigInt(1),        
        whitelistPayload.request,
        whitelistPayload.signature,
        price,
      );
      setMintStatus(TransactionStatus.Success);
    } catch (e) {
      let error = extractError(e);
      if (error.includes('Invalid account')) {
        error = 'You are trying to mint with an invalid wallet.';
      }

      if (error.includes('Exceeds limit')) {
        error = 'You have already minted, please check your wallet.';
      }

      if (error.includes('Unauthorized mint')) {
        error = 'Unauthorized mint.';
      }

      setMintError(error);
      setMintStatus(TransactionStatus.Error);
    } finally {
      setLoadingMint(false);
    }
  }

  return {
    price,
    currentStage,
    canMintPublic,
    canMintWhitelist,
    executeTokenMint: 
      currentStage === 0 ? () => alert("Not live") : currentStage === 1 ? executeTokenWhitelistMint : executeTokenPublicMint,
    closeMintModal,
    isOpenMint,
    mintStatus,
    mintDate,
    mintError,
    setMintError,
    loadingMint
  };
}
