import { useReadContract } from 'wagmi';
import { TOKEN_ABI, TOKEN_CONTRACT_ADDRESS } from '@chad-mint/shared';

export function useTotalSupply() {
  const { data: totalSupply } = useReadContract({
    abi: TOKEN_ABI,
    address: TOKEN_CONTRACT_ADDRESS,
    functionName: 'totalSupply',
    query: {
      refetchInterval: 1000,
    }
  });

  return totalSupply;
}
