import { atom, useAtom } from 'jotai';

const audio = new Audio(
  'https://stiquit-assets.s3.amazonaws.com/audios/CHAD_ANTHEM.mp3'
);
audio.volume = 0.2;
audio.autoplay = false;
audio.loop = true;

const isPlayingAtom = atom(false);

export function useBackgroundMusic() {
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom);

  async function play() {
    await audio.play();
    setIsPlaying(true);
  }

  async function pause() {
    audio.pause();
    setIsPlaying(false);
  }

  return { isPlaying, play, pause };
}
